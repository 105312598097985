import {
  Table,
  message,
  TableColumnsType,
  Button,
  Badge,
  Spin,
  Divider,
  Progress,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ISubTask, ITask, taskList } from "src/service/api/home";
import styled from "styled-components";
import { useAuthorize } from "..";
import Title from "antd/es/typography/Title";

export const Container = styled.div`
  background-color: white;
  // padding: 20px;
  border-radius: 5px;
  /* overflow-y: auto; */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(174, 173, 173, 0.8);
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  // @media (min-width: 768px) {
  //   width: 750px;
  // }
  // @media (min-width: 992px) {
  //   width: 970px;
  // }
  // @media (min-width: 1200px) {
  //   width: 1170px;
  // }
  margin: 0 auto;
`;
const STATE_MAP: any = {
  RUNNING: { state: "processing", text: "RUNNING" },
  SUCCESS: { state: "success", text: "DONE" },
  STOPPED: { state: "default", text: "STOPPED" },
  FAILED: { state: "error", text: "FAILED" },
  WAITING: { state: "warning", text: "WAITING" },
  UPDATING: { state: "processing", text: "UPDATING" },
  EXPRIED: { state: "error", text: "EXPRIED" },
};
export default function TasksContent() {
  const [taskData, setTaskData] = useState<any>();
  const navigate = useNavigate();
  const userAuth = useAuthorize();

  const [isLoading, setIsloading] = useState<boolean>(false);
  useEffect(() => {
    if (!userAuth.isLogin || !localStorage.getItem("token")) {
      navigate("/login");
      return;
    }

    (async () => {
      try {
        setIsloading(true);
        const rep = await taskList();
        if (rep.status === 200 && rep.data?.code === 200) {
          setTaskData(rep.data?.data);
        } else {
          message.error(rep.data?.msg);
          navigate("/login");
        }
        setIsloading(false);
      } catch (error) {
        setIsloading(false);
      }
    })();
  }, [navigate, userAuth.isLogin]);
  const columns: ColumnsType<ITask> = [
    {
      title: "Task ID",
      render: (name: any, record: any, index: number) => (
        <Button
          type="link"
          // disabled={record.sub_tasks.some((item) => item.status !== "SUCCESS")}
          onClick={() =>
            // record.sub_tasks.every(
            //   (item) => item.status === "FAILED" || item.status === "SUCCESS"
            // ) &&
            navigate(`/home/analysis/results`, {
              state: {
                task: record,
                status: record.sub_tasks.every(
                  (item: any) => item.status === "SUCCESS"
                ),
              },
            })
          }
        >
          {record.type === "example"
            ? "example task"
            : `task ${record.task_id}`}
        </Button>
      ),
    },
    // {
    //   title: "Task ID",
    //   key: "task_id",
    //   dataIndex: "task_id",
    // },
    {
      title: "Trait",
      key: "trait",
      dataIndex: "trait",
    },
    {
      title: "Start Time",
      key: "create_at",
      dataIndex: "create_at",
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
    },

    // {
    //   title: "set",
    //   render: (name: any, record, index: number) => (
    //     <Button
    //       type="link"
    //       // disabled={record.sub_tasks.some((item) => item.status !== "SUCCESS")}
    //       onClick={() =>
    //         navigate(`/home/analysis/input`, {
    //           state: { task: record, menuKey: "6", taskId: record.task_id },
    //         })
    //       }
    //     >
    //       Check Config
    //     </Button>
    //   ),
    // },
    {
      title: "Progress",
      key: "progress",
      dataIndex: "progress",
      render: (status,record) => (
        <span>

          {
            <Progress percent={record.sub_tasks[0].progress} size="small" style={{ width: 100, marginLeft: 10 }} />
          }

        </span>
      ),
    }
  ];

  const expandedRowRender = (record: ITask) => {
    const columns: TableColumnsType<ISubTask> = [
      { title: "Sub Task ID", dataIndex: "record_id", key: "record_id" },
      {
        title: "Sub Task Name",
        render: (name: any, text, index: number) =>
          `LocusCompare2 task ${record.task_id}_${index + 1}`,
      },
      // { title: "eqtl id", dataIndex: "eqtl_id", key: "eqtl_id" },
      { title: "tissue name", dataIndex: "tissue", key: "tissue" },
      {
        title: "status",
        dataIndex: "status",
        key: "status",
        render: (status) => (
          <Badge
            status={STATE_MAP[status].state}
            text={STATE_MAP[status].text}
          />
        ),
      },
    ];
    return (
      <Table
        rowKey="record_id"
        columns={columns}
        dataSource={record.sub_tasks}
        pagination={false}
      />
    );
  };
  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Container style={{maxWidth:"1170px", margin:"0 auto"}}>
        <div className="layout-content-display">
          <Title level={3} style={{ textAlign: 'center' }}>Task List</Title> <Divider />
          {taskData && (
            <Table
              rowKey="task_id"
              expandable={{
                expandedRowRender,
              }}
              columns={columns}
              dataSource={[
                ...taskData[0].map((item: any) => ({
                  ...item,
                  type: "example",
                })),
                ...taskData[1].reverse(),
              ]}
            />
          )}
        </div>
      </Container>
    </Spin>
  );
}
