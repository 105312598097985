import _ from "lodash";
import { IManhattan, MANHATTAN_FILE_NAME } from "src/report";
import { GENES_FILE_NAME, IReport, REPORTS_FILE_NAME } from "../report";
import {
  getJsonPData,
  GET_MABHATTAN_REPORT,
  GET_GENES_REPORT,
  GET_TOOLS_REPORT,
  RANK_MAP,
} from "../util/jsonp";
import {
  IManHattan,
  ITask,
  heatmapList,
  manhattanPlot,
  upsetList,
} from "./api/home";
import { genome_data_merged } from "src/util/chromosome";
import { THRESHOLD_MAP_DETIAL } from "src/container/home/analysis/ResultPage";
export const SORT_TYPE_MAP: any = [
  { name: "intact", type: "asc" },
  { name: "intact", type: "desc" },
  { name: "rank", type: "asc" },
  { name: "rank", type: "desc" },
  { name: "geo", type: "asc" },
  { name: "geo", type: "desc" },
];

// export function getAnalysisResultMainData(isOffline: boolean, task: ITask, tool: any) {
export function getAnalysisResultMainData(isOffline: boolean, task: ITask) {
  return new Promise((resolve, reject) => {
    let responseData: any = [];
    console.log("isOffline",isOffline);
    // console.log("tool",tool);
    if (isOffline) {
      getJsonPData(GET_TOOLS_REPORT, REPORTS_FILE_NAME).then(
        (data: IReport[]) => {
          if (data.length > 0) {
            let traitObj: any = {};
            data.forEach((item: IReport) => {
              if (traitObj[item.trait] > -1) {
                if (
                  responseData[traitObj[item.trait]].children.some(
                    (traitItem: any) => traitItem.name === item.tissue
                  )
                ) {
                  responseData[traitObj[item.trait]].children = responseData[
                    traitObj[item.trait]
                  ].children.map((traitItem: any) => {
                    if (traitItem.name === item.tissue) {
                      traitItem.children.push({
                        tool: item.tool_name,
                        value: item.tool_name,
                        label: item.tool_name,
                      });
                    }
                    return traitItem;
                  });
                } else {
                  responseData[traitObj[item.trait]].children.push({
                    name: item.tissue,
                    value: item.tissue,
                    label: item.tissue,
                    population: item.population,
                    children: [
                      {
                        tool: item.tool_name,
                        value: item.tool_name,
                        label: item.tool_name,
                      },
                    ],
                  });
                }
              } else {
                traitObj[item.trait] = responseData.length;
                responseData.push({
                  name: item.trait,
                  value: item.trait,
                  label: item.trait,
                  children: [
                    {
                      name: item.tissue,
                      value: item.tissue,
                      label: item.tissue,
                      population: item.population,
                      children: [
                        {
                          tool: item.tool_name,
                          value: item.tool_name,
                          label: item.tool_name,
                        },
                      ],
                    },
                  ],
                });
              }
            });
            resolve({ study: data[0].study1, data: responseData });
          } else {
            resolve({});
          }
          
        }
      );
    } else {
      if (!task) {
        resolve({});
        return;
      }
      const triatName = "任务ID：" + task.task_id;
      resolve({
        study: triatName,
        data: [
          {
            name: triatName,
            value: task.task_id,
            label: triatName,
            children: task.sub_tasks.map((sub) => {
              return {
                name: sub.tissue,
                value: sub.eqtl_id,
                label: sub.tissue,
                recordId: sub.record_id,
                children: Object.keys(THRESHOLD_MAP_DETIAL).map((tool) => ({
                  name: tool,
                  value: tool,
                  label: tool,
                })
                // children: {
                //   name: tool,
                //   value: tool,
                //   label: tool,
                // }
            )};
            }),
          },
        ],
        
      });
    }
  });

}

export function getAnalysisResultTraitTissueGenesData(data: {
  isOffline: boolean;
  trait: any;
  tissue: any;
  ascendRadio: any;
  thresholds: any;
  singleTissueQueryParams: any;
  mutilTissueQueryParams: any;
  orderByTissue: any;
}) {
  const {
    isOffline,
    trait,
    tissue,
    ascendRadio,
    thresholds,
    singleTissueQueryParams,
    mutilTissueQueryParams,
    orderByTissue,
  } = data;
  return new Promise(async (resolve, reject) => {
    if (isOffline) {
      let dataSource: any, columns: any;
      if (!trait.children) resolve({ dataSource, columns });
      columns = trait?.children.map((item: any) => ({
        title: item.name,
        dataIndex: item.name,
        key: item.name,
      }));
      let geneObj: any = {};
      trait.children
        .reduce((cur: any, next: any) => {
          return cur.then((data: any) => {
            return new Promise((resolve: any, reject: any) => {
              getJsonPData(
                GET_GENES_REPORT,
                `${trait.name}/${next.name}/${GENES_FILE_NAME}`
              ).then((data2: any) => {
                let geneList: any = _.orderBy(
                  data2,
                  RANK_MAP[SORT_TYPE_MAP[ascendRadio].name],
                  SORT_TYPE_MAP[ascendRadio].type
                );
                resolve({ ...data, [next.name]: geneList });
              });
            });
          });
        }, Promise.resolve([]))
        .then((responseList: any) => {
          Object.keys(responseList).forEach((tissuItem: any) => {
            responseList[tissuItem].forEach((geneItem: any) => {
              if (!geneObj[geneItem.gene_id]) {
                geneObj[geneItem.gene_id] = {
                  gene: geneItem.gene_id,
                };
              }
              geneObj[geneItem.gene_id][tissuItem] = geneItem;
            });
          });
          dataSource = Object.keys(geneObj).map((key: any) => ({
            key,
            ...geneObj[key],
          }));
          resolve({
            dataSource,
            mutiSize: dataSource.length,
            columns,
            tissueList: responseList,
          });
        });
    } else {
      let dataSource: any, columns: any, responseList: any;

      if (!trait.children) resolve({ dataSource, columns });
      columns = trait?.children.map((item: any) => ({
        title: item.name,
        dataIndex: item.name,
        key: item.name,
      }));

      const limits = [
        thresholds.coloc,
        thresholds.ecaviar,
        thresholds.fastenloc,
        thresholds.fusion,
        thresholds.predixcan,
        thresholds.smr,
      ];
      try {
        const rep: any = await heatmapList({
          task_id: trait.value,
          limits,
          order_by: SORT_TYPE_MAP[ascendRadio].name,
          order: SORT_TYPE_MAP[ascendRadio].type,
          page: mutilTissueQueryParams.page - 1,
          page_size: mutilTissueQueryParams.page_size,
          order_by_tissue: orderByTissue.name
        });
        console.log("rep111:", rep)
        const repUpset: any = await upsetList({
          colot_record_id: tissue.recordId,
          limits,
          order_by: singleTissueQueryParams.orderBy,
          order: singleTissueQueryParams.order,
          page: singleTissueQueryParams.page - 1,
          page_size: singleTissueQueryParams.page_size,
        });
        console.log("repUpset", repUpset)
        resolve({
          code: repUpset?.data?.code,
          dataSource: rep?.data?.data?.list?.map((item: any) => ({
            ...item,
            key: item.gene_id,
            gene: item.gene_id,
          })),
          columns,
          mutiSize: rep?.data?.data?.size,
          singleSize: repUpset?.data?.data?.size,
          tissueList: {
            [tissue.value]: repUpset?.data?.data?.list?.map((item: any) => ({
              ...item,
              gene_id: item.name,
            })),
          },
        });
      } catch (error) {
        resolve({
          code: 400
        })
      }
    }
  });
}

export async function getManhattanPlot(data: {
  isOffline: boolean;
  trait: string;
  tissue: string;
  recordId: any;
}) {
  const { isOffline, trait, tissue, recordId } = data;
  if (isOffline) {
    const data = await getJsonPData(
      GET_MABHATTAN_REPORT,
      `${trait}/${tissue}/${MANHATTAN_FILE_NAME}`
    );
    return data.map((item: any) => ({
      category: item.category,
      chrom: item.chrom,
      position:
        item.position + genome_data_merged[item.chrom - 1]?.genome_start,
      pvalue: item.pvalue,
      snp: item.snp,
      var_id_: item.var_id_,
    }));
  } else {
    return ((await manhattanPlot(recordId))?.data?.data as IManHattan[]).map(
      (item) => ({
        category: item.chrom % 2 === 1 ? "single_chr" : "double_chr",
        chrom: item.chrom,
        position: item.position,
        pvalue: item.p_value,
        snp: item.snp,
        var_id_: `chr${item.chrom}_${item.position}`,
      })
    );
  }
}
