import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Card, Divider } from "antd";
import { Container } from "../tasks";
import { useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title";
export default function ProfileContent() {
  const navigate = useNavigate();
  return (
    <Container style={{maxWidth:"1170px", margin:"0 auto"}}>
      <Title level={3}>Profile</Title>
      <Divider />
      <Card>
        <div className="layout-content-display">
          <Title level={5}>email</Title>
          <p>{localStorage.getItem("email")}</p>
          <Button
            style={{ marginTop: "20px" }}
            onClick={() => {
              localStorage.setItem("token", "");
              localStorage.setItem("email", "");
              navigate("/");
            }}
          >
            Logout
          </Button>
        </div>
      </Card>
    </Container>
  );
}
