import { UploadOutlined, ContainerOutlined } from "@ant-design/icons";
import { Progress, Upload, message } from "antd";
import { RcFile } from "antd/lib/upload";
import { useCallback, useState } from "react";
import { css } from "styled-components";
import yaml from "js-yaml";  // 用于解析 YAML 文件

export type FileUploaderProps = {
  accept?: string;
  progress?: number;
  text?: string;
  description?: string;
  customRequest?: (params: any) => any;
  isValidFileFormat?: (fileName: string) => boolean;
};

const useStyles = (props: FileUploaderProps) => ({
  root: css`
    .ant-upload {
      background-color: #f0f2f5;
      border: 1px dashed #1890ff;
      border-radius: 8px;
      padding: 20px;
    }
  `,
  icon: css`
    color: #1890ff;
    margin-bottom: 8px;
  `,
  text: css`
    color: #1890ff;
    font-weight: 500;
    margin-bottom: 4px;
  `,
  description: css`
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  `,
  progress: css`
    padding: 4px 0 0 22px;
    line-height: 0;
    .ant-progress-bg {
      height: 2px !important;
    }
  `,
});

function LocalToolConfigUploader(props: FileUploaderProps) {
  const {
    progress,
    text = "Click or drag file to this area to upload",
    description = "Only .yaml or .yml files are allowed.",
    customRequest,
    isValidFileFormat,
    ...restProps
  } = props;
  const styles = useStyles(props);

  const [fileList, setFileList] = useState<RcFile[]>([]);

  const handleBeforeUpload = useCallback(
    (file: RcFile) => {
      const isYaml = file.name.endsWith(".yaml") || file.name.endsWith(".yml");
      if (!isYaml) {
        message.error("You can only upload YAML files!");
        return Upload.LIST_IGNORE;
      }

      setFileList([file]);  // 只允许单文件上传
      const fileReader = new FileReader();
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        try {
          const result = event.target?.result;
          if (typeof result === "string") {
            const parsedYaml = yaml.load(result);
            console.log("Parsed YAML file:", parsedYaml);
          }
        } catch (error) {
          message.error("Failed to parse YAML file.");
        }
      };
      fileReader.readAsText(file);

      return false;  // 防止文件自动上传
    },
    [isValidFileFormat]
  );

  const handleRemove = useCallback(() => {
    setFileList([]);
  }, []);

  return (
    <div css={styles.root}>
      <Upload.Dragger
        fileList={fileList}
        beforeUpload={handleBeforeUpload}
        customRequest={customRequest || (() => {})}
        onRemove={handleRemove}
        {...restProps}
      >
        <div css={styles.icon}>
          <ContainerOutlined style={{ fontSize: 32 }} />
        </div>
        <div css={styles.text}>{text}</div>
        <div css={styles.description}>{description}</div>
      </Upload.Dragger>
      {progress != null && (
        <Progress
          css={styles.progress}
          strokeColor="#1890ff"
          percent={progress}
          showInfo={false}
        />
      )}
    </div>
  );
}

export default LocalToolConfigUploader;
