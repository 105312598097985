import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DesktopOutlined,
  ProfileOutlined,
  UserOutlined,
  ExperimentOutlined,
  KeyOutlined,
  CalendarOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

import zhejiang_png from "../../zhejiang.png";
import nus_png from "../../nus.png";
import { Affix, Avatar, Button, Divider, Dropdown, FloatButton, MenuProps } from "antd";
import {
  HomeOutlined,
  FileUnknownOutlined,
  FileOutlined,
  PaperClipOutlined,
  AreaChartOutlined,
  LineChartOutlined,
  AppstoreOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import {
  Link,
  Route,
  HashRouter,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";


const { Header, Content, Footer, Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const AvatarDiv = styled(Avatar)`
  flex: none;
`;
const SiteLayout = styled(Layout)``;

const items: MenuItem[] = [
  getItem(
    "",
    "1",
    <Link to="/home">
      {/* <HomeOutlined style={{ fontSize: "18px" }} /> */}
      <text className="menubar">Home</text>
    </Link>
  ),
  getItem(
    "",
    "2",
    <Link to="/home/tutorial">
      {/* <KeyOutlined style={{ fontSize: "18px" }} /> */}
      <text className="menubar">Tutorial</text>
    </Link>
  ),
  getItem(
    "",
    "5",
    <Link to="/home/reference">
      {/* <PaperClipOutlined style={{ fontSize: "18px" }} /> */}
      <text className="menubar">Reference</text>
    </Link>
  ),
];

const myItems: MenuItem[] = [
  getItem(
    "",
    "4",
    <Link to="/home/analysis">
      {/* <AreaChartOutlined style={{ fontSize: "18px" }} /> */}
      <text className="menubar">Analysis</text>
    </Link>
    // [
    //   getItem(
    //     "Input",
    //     "6",
    //     <Link to="/home/analysis/input">
    //       <ProfileOutlined style={{ fontSize: "18px" }} />
    //     </Link>
    //   ),
    // ]
  ),
  getItem(
    "",
    "8",
    <Link to="/home/tasks">
      {/* <ExperimentOutlined style={{ fontSize: "18px" }} /> */}
      <text className="menubar">Jobs</text>
    </Link>
  ),
];
interface AuthContextType {
  isLogin: boolean;
}
const AuthContext = createContext<AuthContextType>({
  isLogin: false,
});

const HomePage: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // 监听窗口大小变化
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selectedKeys, setSelectedKeys] = useState(
    [...items, ...myItems]
      .filter((item: any) => {
        return (
          location.pathname.endsWith(item.icon.props.to as string) ||
          (location.pathname.endsWith("home/analysis/results") &&
            item.icon.props.to === "/home/tasks")
        );
      })
      .map((item: any) => item.key as string)
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.menuKey) {
      setSelectedKeys(location.state?.menuKey);
    }
  }, [location.state?.menuKey]);

  const menu = (
    <Menu
      selectedKeys={selectedKeys}
      onClick={({ key }) => {
        setSelectedKeys([key]);
      }}
      items={[...items, ...myItems]}
    />
  );
  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          isLogin: location.state?.login || localStorage.getItem("token"),
        }),
        [location.state?.login]
      )}
    >
      <Layout style={{ height: "100%", width: "100%" }}>
        {/* <div
            style={{
              height: 32,
              lineHeight: "32px",
              margin: 16,
              textAlign: "center",
              background: "rgba(255, 255, 255, 0.2)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            LocusCompare v2
          </div> */}
        <Header style={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', background: "#4f5d73", }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%", width:"100%"}}>
            <div
              className="logo_con"
              style={{
                display: "flex",
                alignItems: "center", // 垂直居中
                // background: "white",
                height: "100%",
                marginLeft: "0",
                justifyContent: "center", // 水平居中
              }}
            >
              <img src={nus_png} className="logo_con_img" alt="" style={{marginRight: "10%", alignItems: 'center'}} />
              <Divider style={{ height: "100%" }} type="vertical" />
              <img src={zhejiang_png} className="logo_con_img" alt="" style={{ marginLeft: "10%", marginRight:'10%', alignItems: 'center' }} />
            </div>
            {!isMobile ? (
              <Menu
                className="menu_header"
                theme="light"
                selectedKeys={selectedKeys}
                onClick={({ item, key, keyPath, domEvent }) => setSelectedKeys([key])}
                style={{ background: "#4f5d73", width:"50%", textAlign: 'center', height: "105%", alignItems:'center', justifyContent: "flex-start", marginLeft:"3%", padding: "0px", color:"white"  }}  // 中央对齐的菜单
                mode="horizontal"
                items={[...items, ...myItems]}
              />
            ) : (
              <Dropdown overlay={menu} trigger={['click']}>
                <Button
                  icon={<BarsOutlined />}
                  style={{ color: "white", background: "rgb(140, 140, 171)", marginLeft: "5%" }}
                />
              </Dropdown>
            )}
            
          </div>
          <div style={{ marginLeft: 'auto' }}>
            {location.state?.login || localStorage.getItem("token") ? (
              <FloatButton
                style={{ top: 12 }}
                icon={<UserOutlined />}
                type="default"
                onClick={() => {
                  navigate("/home/profile");
                  setSelectedKeys([""]);
                }}
              />
            ) : null}
          </div>
        {/* </Header> */}
        
        {location.state?.login || localStorage.getItem("token") ? (
          <FloatButton
            style={{ top: 12 }}
            icon={<UserOutlined />}
            type="default"
            onClick={() => {
              navigate("/home/profile");
              setSelectedKeys([""]);
            }}
          />
        ) : (
          <Affix className="AffixDiv" style={{ top: "1.5%", marginRight:"2%"}}>
            <Button
              style={{ width: 100, height:"10%",  fontSize: "100%" }}
              // size="large"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </Affix>
        )}

      </Header>
      
      {/* 维修公告 */}
      {/* <div className="announcement">
        <i>!</i>Service Downtime Announcement: Our service will be temporarily unavailable due to a major software update scheduled from 17:00 on November 1, 2024 to 17:00 on November 4, 2024 (Singapore Time). During this period, access to our service will be unavailable. We sincerely apologize for any inconvenience this may cause and appreciate your understanding and support.
      </div> */}

        <div className="content_menu">
          <Content>
            <Outlet />
          </Content>
        </div>
      </Layout>
    </AuthContext.Provider>
  );
};

export function useAuthorize() {
  return useContext(AuthContext);
}

export default HomePage;