import React, { useEffect, useState, useCallback } from "react";
import * as _ from "lodash";
import { Collapse, Descriptions, Row, Spin, Table, Form, Input, Card } from "antd";
import { getColocConfig, getGenomicLoci } from "src/service/api/home";
import InputPage from "../InputPage";
import { DownloadOutlined} from "@ant-design/icons";
import {
  Button
} from "antd";
const { Panel } = Collapse;
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 18 },
};

export default React.memo(function GwasInputComponent(props: {
  task_id: number;
  summaryInfo: any;
  isLoadingForSummary: any;
}) {
  const { task_id, summaryInfo, isLoadingForSummary } = props;
  const [dataSource, setDataSource] = useState<any>([]);

  const [isLoadingForClumping, setIsloadingForClumping] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsloadingForClumping(true);
        const data = await getGenomicLoci(task_id);
        setDataSource(
          data?.data?.data?.map((item: any, index: any) => ({
            ...item,
            index: index + 1,
          }))
        );
        setIsloadingForClumping(false);
      } catch (error) {
        setIsloadingForClumping(false);
      }
    })();
  }, [task_id]);

  const columns = [
    {
      title: "lead SNP",
      dataIndex: "rs_id",
      key: "rs_id",
    },
    {
      title: "start",
      dataIndex: "start",
      key: "start",
    },
    {
      title: "end",
      dataIndex: "end",
      key: "end",
    },
    {
      title: "variant id",
      dataIndex: "var_id",
      key: "var_id",
    },
    {
      title: "the number of SNPs in this loci",
      dataIndex: "total_snp",
      key: "total_snp",
    },
  ];  
  
  const downloadLogfile = useCallback(() => {
    const downloadLink = document.createElement("a");
    downloadLink.href = summaryInfo?.log_file;
    downloadLink.download = "output.log";
    downloadLink.click();
  }, [summaryInfo?.log_file]);
  

  return (
    <div className="GwasContainer">
      <div style={{
        border: "1px solid #d3d3d3",
        padding: "20px",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        // width: "60%",
        alignContent: "center",
        margin: "0 auto"
      }}>
        <h3 style={{
          fontSize: "20px",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          Input
          <Button icon={<DownloadOutlined />} onClick={downloadLogfile}>
            Log File
          </Button>
        </h3>
  
        {/* **参数列表** */}
        <div style={{
          marginTop: "20px",
          padding: "12px",
          borderRadius: "6px",
          background: "#fff",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.08)"
        }}>
          <h4 style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
            📋 Summary Information
          </h4>
          <ul style={{ listStyleType: "none", padding: "0" }}>
            {summaryInfo?.trait && (
              <li style={{
                background: "#f6f6f6",
                borderRadius: "6px",
                padding: "8px",
                marginBottom: "6px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
              }}>
                <strong style={{ fontSize: "16px", color: "#0073e6" }}>Trait Name:</strong>
                <span style={{ marginLeft: "8px", color: "#444" }}>{summaryInfo.trait}</span>
              </li>
            )}
            {summaryInfo?.sample_size && (
              <li style={{
                background: "#f6f6f6",
                borderRadius: "6px",
                padding: "8px",
                marginBottom: "6px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
              }}>
                <strong style={{ fontSize: "16px", color: "#0073e6" }}>Sample Size:</strong>
                <span style={{ marginLeft: "8px", color: "#444" }}>{summaryInfo.sample_size}</span>
              </li>
            )}
            {summaryInfo?.gwas_pval_threshold && (
              <li style={{
                background: "#f6f6f6",
                borderRadius: "6px",
                padding: "8px",
                marginBottom: "6px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
              }}>
                <strong style={{ fontSize: "16px", color: "#0073e6" }}>GWAS Threshold:</strong>
                <span style={{ marginLeft: "8px", color: "#444" }}>{summaryInfo.gwas_pval_threshold}</span>
              </li>
            )}
            {summaryInfo?.eqtl && (
              <li style={{
                background: "#f6f6f6",
                borderRadius: "6px",
                padding: "8px",
                marginBottom: "6px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
              }}>
                <strong style={{ fontSize: "16px", color: "#0073e6" }}>Eqtl Tissue:</strong>
                <span style={{ marginLeft: "8px", color: "#444" }}>{summaryInfo.eqtl.join(", ")}</span>
              </li>
            )}
            {summaryInfo?.tools && (
              <li style={{
                background: "#f6f6f6",
                borderRadius: "6px",
                padding: "8px",
                marginBottom: "6px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
              }}>
                <strong style={{ fontSize: "16px", color: "#0073e6" }}>Tools:</strong>
                <span style={{ marginLeft: "8px", color: "#444" }}>{summaryInfo.tools}</span>
              </li>
            )}
            {summaryInfo?.significant_loci_count && (
              <li style={{
                background: "#f6f6f6",
                borderRadius: "6px",
                padding: "8px",
                marginBottom: "6px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
              }}>
                <strong style={{ fontSize: "16px", color: "#0073e6" }}>Significant GWAS Loci Count:</strong>
                <span style={{ marginLeft: "8px", color: "#444" }}>{summaryInfo.significant_loci_count}</span>
              </li>
            )}
            {summaryInfo?.match_snp_count && (
              <li style={{
                background: "#f6f6f6",
                borderRadius: "6px",
                padding: "8px",
                marginBottom: "6px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
              }}>
                <strong style={{ fontSize: "16px", color: "#0073e6" }}>Match SNP Count:</strong>
                <span style={{ marginLeft: "8px", color: "#444" }}>{summaryInfo.match_snp_count}</span>
              </li>
            )}
          </ul>
        </div>
  
        {/* **Tool Config** */}
        {summaryInfo?.tool_config && (
          <div style={{
            marginTop: "20px",
            padding: "12px",
            borderRadius: "6px",
            background: "#fff",
            boxShadow: "0 1px 5px rgba(0, 0, 0, 0.08)"
          }}>
            <h4 style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
              ⚙️ Tool Config
            </h4>
            <ul style={{ listStyleType: "none", padding: "0" }}>
              {Object.entries(summaryInfo.tool_config).map(([tool, config]) => {
                // **自动替换 tool 名称**
                const toolNameMap: Record<string, string> = {
                  coloc: "Coloc",
                  smr: "SMR",
                  ecaviar: "eCAVIAR",
                  fusion: "FUSION",
                  fastenloc: "fastENLOC",
                  predixcan: "PrediXcan",
                };
                const displayToolName = toolNameMap[tool.toLowerCase()] || tool;
  
                return (
                  <li key={tool} style={{
                    background: "#f6f6f6",
                    borderRadius: "6px",
                    padding: "8px",
                    marginBottom: "6px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)"
                  }}>
                    <strong style={{ fontSize: "16px", color: "#0073e6" }}>
                      {displayToolName}:
                    </strong>
                    {typeof config === "object" ? (
                      <ul style={{ listStyleType: "none", paddingLeft: "12px", marginTop: "6px" }}>
                        {Object.entries(config).map(([key, value]) => (
                          <li key={key} style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "4px 0",
                            borderBottom: "1px solid #ddd"
                          }}>
                            <span style={{ fontWeight: "500", color: "#444" }}>{key}:</span>
                            <span style={{ color: "#666" }}>{value.toString()}</span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span style={{ marginLeft: "8px", color: "#444" }}>{config.toString()}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
  
});
