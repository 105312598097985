import {
  Button,
  Spin,
  Card,
  Col,
  Collapse,
  Divider,
  FloatButton,
  Form,
  InputNumber,
  Pagination,
  Radio,
  Row,
  Select,
  Space,
  Table,
  TableProps,
  Tooltip,
} from "antd";
import { DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  geneDetail,
  geneListPlot,
  getColocConfig,
  mapAntdOrderToServer,
  snpList,
} from "src/service/api/home";
import styled from "styled-components";
import {
  getAnalysisResultMainData,
  getAnalysisResultTraitTissueGenesData,
} from "../../../service/api-service";
import "./ResultPage.css";
import BarChartComponent from "./component/BarChartComponent";
import HeatChart from "./component/HeatComponent";
import LocusPlotComponent from "./component/LocuscPlotComponent";
import ManhattanPlotComponent from "./component/ManhattanPlotComponent";
import QQplot from "./component/QqplotComponent";
import UpsetComponent from "./component/UpsetComponent";
import GwasInfoComponent from "./component/GwasInfoComponent";
import GwasInputComponent from "./component/GwasInputComponent";
import MatchedSNPsummary from "./component/MatchedSNPsummary";

import CisEqtlComponent from "./component/CisEqtlComponent";
import JSZip from "jszip";
import Title from "antd/es/typography/Title";
import InputPage from "./InputPage";
import { parseValue } from "src/util/common";
import React from 'react';
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { InfoCircleOutlined } from "@ant-design/icons";
import { title } from "process";


const ResultRoot = styled.div<{ isOffline: boolean }>`
  ${(props) => (props.isOffline ? "background: rgb(248 249 250);" : "")};
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(174, 173, 173, 0.8);
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const tool_map: any = {
  coloc: "red",
  predixcan: "blue",
  smr: "green",
  fastenloc: "orange",
  fusion: "pink",
  ecaviar: "purple",
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
let tool: string;
export const THRESHOLD_MAP_DETIAL: any = {
  coloc: { value: 0.75, type: ">=", label: "coloc", pLabel: "H4", value_lable: "0.75" },
  predixcan: { value: 1e-6, type: "<=", label: "PrediXcan", pLabel: "p-value", value_lable: "1e-6" },
  smr: { value: 1e-6, type: "<=", label: "SMR", pLabel: "p-value", value_lable: "1e-6" },
  // fastenloc: { value: 0.5, type: ">=", label: "fastEnloc", pLabel: "LCP" },
  fastenloc: { value: 0.5, type: ">=", label: "fastEnloc", pLabel: "GRCP", value_lable: "0.5" },
  fusion: { value: 1e-6, type: "<=", label: "FUSION", pLabel: "p-value", value_lable: "1e-6" },
  ecaviar: { value: 0.01, type: ">=", label: "eCAVIAR", pLabel: "CLPP", value_lable: "0.01" },
};
console.log('THRESHOLD_MAP_DETIAL:', THRESHOLD_MAP_DETIAL);
console.log('THRESHOLD_MAP_DETIAL[coloc]:', THRESHOLD_MAP_DETIAL['coloc']);
const { Panel } = Collapse;
export default function ResultsPage(props: any) {

  const [DisplayFlag, setDisplayFlag] = useState(1);
  const [DisplayFlagBefore, setDisplayFlagBefore] = useState(1);

  const [DisplayTab, setDisplayTab] = useState<string>();
  const [DisplayTabNot, setDisplayTabNot] = useState<string>();
  const [DisplayConfigurations, setDisplayConfigurations] = useState<string>();
  const [DisplayGWASsummary, setDisplayGWASsummary] = useState<string>();
  const [DisplayNumber, setDisplayNumber] = useState<string>();
  const [DisplayColocThre, setDisplayColocThre] = useState<string>();
  const [DisplayMultiTissue, setDisplayMultiTissue] = useState<string>();
  const [DisplayTissueSelect, setDisplayTissueSelect] = useState<string>();
  const [DisplayUpsetplot, setDisplayUpsetplot] = useState<string>();
  const [DisplayColocplot, setDisplayColocplot] = useState<string>();
  const [DisplayGeneselect, setDisplayGeneselect] = useState<string>();

  // const [DisplayConfigurations, setDisplayConfigurations] = useState<string>("block");
  // const [DisplayGWASsummary, setDisplayGWASsummary] = useState<string>("block");
  // const [DisplayNumber, setDisplayNumber] = useState<string>("none");
  // const [DisplayColocThre, setDisplayColocThre] = useState<string>("none");
  // const [DisplayTissueSelect, setDisplayTissueSelect] = useState<string>("none");
  // const [DisplayUpsetplot, setDisplayUpsetplot] = useState<string>("none");
  // const [DisplayColocplot, setDisplayColocplot] = useState<string>("none");
  // const [DisplayGeneselect, setDisplayGeneselect] = useState<string>("none");


  const handleButtonClick = (value: number) => {

    setDisplayFlag(value);
  };

  useEffect(() => {

    if (DisplayFlag == 1) {
      setDisplayTab("flex");
      setDisplayTabNot("none");
      setDisplayConfigurations("block");
      setDisplayGWASsummary("block");
      setDisplayNumber("none");
      setDisplayColocThre("none");
      setDisplayMultiTissue("none");
      setDisplayTissueSelect("none");
      setDisplayUpsetplot("none");
      setDisplayColocplot("none");
      setDisplayGeneselect("none");
    }
    else if (DisplayFlag == 2) {
      setDisplayTab("flex");
      setDisplayTabNot("none");
      setDisplayConfigurations("none");
      setDisplayGWASsummary("none");
      setDisplayNumber("block");
      setDisplayColocThre("block");
      setDisplayMultiTissue("block");
      setDisplayTissueSelect("block");
      setDisplayUpsetplot("block");
      setDisplayColocplot("block");
      setDisplayGeneselect("block");
    }
    else if (DisplayFlag == 100) {
      setDisplayTab("none");
      setDisplayTabNot("flex");
      setDisplayConfigurations("block");
      setDisplayGWASsummary("block");
      setDisplayNumber("block");
      setDisplayColocThre("block");
      setDisplayMultiTissue("block");
      setDisplayTissueSelect("block");
      setDisplayUpsetplot("block");
      setDisplayColocplot("block");
      setDisplayGeneselect("block");
    }
    else if (DisplayFlag == 101) {
      setDisplayTab("none");
      setDisplayTabNot("none");
      setDisplayConfigurations("none");
      setDisplayGWASsummary("none");
      setDisplayNumber("none");
      setDisplayColocThre("none");
      setDisplayMultiTissue("none");
      setDisplayTissueSelect("none");
      setDisplayUpsetplot("none");
      setDisplayColocplot("none");
      setDisplayGeneselect("none");
    }

    // console.log('DisplayFlag:', DisplayFlag);
    // console.log('DisplayConfigurations:', DisplayConfigurations);
    // console.log('DisplayGWASsummary:', DisplayGWASsummary);
    // console.log('DisplayNumber:', DisplayNumber);
    // console.log('DisplayColocThre:', DisplayColocThre);
    // console.log('DisplayTissueSelect:', DisplayTissueSelect);
    // console.log('DisplayUpsetplot:', DisplayUpsetplot);
    // console.log('DisplayColocplot:', DisplayColocplot);
    // console.log('DisplayGeneselect:', DisplayGeneselect);
  }, [DisplayFlag, DisplayConfigurations, DisplayGWASsummary, DisplayNumber, DisplayColocThre, DisplayTissueSelect, DisplayUpsetplot, DisplayColocplot, DisplayGeneselect]);

  const { isOffline } = props;

  const [mutilTissueQueryParams, setHeatmapQueryParams] = useState({
    page_size: 10,
    page: 1,
  });
  const [singleTissueQueryParams, setSingleTissueQueryParams] = useState({
    page_size: 10,
    page: 1,
    orderBy: "rank",
    order: "ASC",
  });
  const initialValues = useMemo(() => {
    const values: any = {};
    Object.keys(THRESHOLD_MAP_DETIAL).forEach((field) => {
      values[field] = THRESHOLD_MAP_DETIAL[field].value;
    });
    return values;
  }, []);
  const [mainData, setMainData] = useState<any>();
  const [traitSelect, setTraitSelect] = useState<any>({});
  const [tissueSelect, setTissueSelect] = useState<any>({});
  const [tissueToOrderBy, setTissueToOrderBy] = useState<any>({});

  const [toolSelect, setToolSelect] = useState<any>({});
  const [ascendRadio, setAscendRadio] = useState<any>(isOffline ? 1 : 2);
  const [thresholds, setThresholds] =
    useState<typeof THRESHOLD_MAP_DETIAL>(initialValues);
  const [geneTissueTable, setGeneTissueTable] = useState<any>({
    dataSource: [],
    columns: [],
  });
  const [tissueGeneList, setTissueGeneList] = useState<any>();
  const [isNoColocResult, setisNoColocResult] = useState<string>();
  const [isColocResult, setisColocResult] = useState<string>();
  const [singleAllTissueGeneList, setSingleAllTissueGeneList] = useState<any>(
    []
  );
  const [summaryInfo, setSummaryInfo] = useState<any>();
  const [singleAllTissueGeneSnpList, setSingleAllTissueGeneSnpList] =
    useState<any>([]);
  // 存储manhattan抽屉信息
  const [collapseNum, setCollapseNum] = useState<Partial<any>>([]);
  const [geneSelect, setGeneSelect] = useState<Partial<any>>();
  const [locusComponentShow, setLocusComponentShow] = useState<boolean>(false);
  const [effectsizeShow, seteffectsizeShow] = useState<boolean>(false);

  const [isLoadingForSummary, setIsloadingForSummary] =
    useState<boolean>(false);
  const [isLoadingForHeatPlot, setIsloadingForHeatPlot] =
    useState<boolean>(false);
  const [isLoadingForBarChart, setIsloadingForBarChart] =
    useState<boolean>(false);
  const [isLoadingForLocusPlot, setIsloadingForLocusPlot] =
    useState<boolean>(false);

  const [form] = Form.useForm();
  let location: any = null;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    location = useLocation();
  } catch (error) {
    console.log(error);
  }
  const status = location?.state?.status;
  console.log("location", location)
  useEffect(() => {
    (async () => {
      try {
        if (traitSelect.value) {
          console.log("traitSelect.value: ", traitSelect.value)
          setIsloadingForSummary(true);
          const summ = await getColocConfig(traitSelect.value);
          setSummaryInfo(summ?.data?.data);
          // console.log("SummaryInfo:**:", summ)
          tool = summ?.data?.data?.tools.split(',')[0]
          // console.log("tool: ", tool)
          setToolSelect({name: tool, value:tool, label:tool});
          setIsloadingForSummary(false);
        }
      } catch (e) {
        setIsloadingForSummary(false);
      }
    })();
  }, [traitSelect.value]);
  
  useEffect(() => {
    if (!isOffline) setIsloadingForBarChart(true);
    (async () => {
      if (!toolSelect.value || !tissueSelect.recordId) return;
      try {
        const dataList: any = await geneListPlot({
          // tool: toolSelect.value,
          tool: toolSelect.value === "fastenloc" ? "enloc" : toolSelect.value,
          colot_record_id: tissueSelect.recordId,
        });
        // console.log("dataList?.data?.data:", dataList?.data?.data)
        if (dataList?.data?.data?.length > 0) {
          setSingleAllTissueGeneList(dataList?.data?.data);
        }
        // 默认显示的基因 （列表第一个，列表按照gene_id大小排序）
        setGeneSelect({ value: dataList?.data?.data[0].gene_id, label: dataList?.data?.data[0].gene_id });
        setAndGeneToolValue(dataList?.data?.data[0].gene_id)
        setIsloadingForBarChart(false);
      } catch (error) {
        console.log("await geneListPlot error:", error)
        setIsloadingForBarChart(false);
      }
    })();
  }, [
    isOffline,
    location?.state?.task?.task_id,
    tissueSelect.recordId,
    toolSelect,
    traitSelect.value,
  ]);

  const mutilTissueHandleChange = useCallback((page: any, pageSize: any) => {
    setHeatmapQueryParams((prev) => ({
      ...prev,
      page,
      page_size: pageSize,
    }));
  }, []);
  const singleTissueHandleChange: TableProps<any>["onChange"] = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      let param = {};
      if (extra.action === "paginate") {
        param = {
          ...param,
          page: pagination.current,
          page_size: pagination.pageSize,
        };
      }
      if (extra.action === "sort" && !Array.isArray(sorter)) {
        console.log(sorter);
        param = {
          ...param,
          orderBy: sorter.field,
          order: mapAntdOrderToServer(sorter.order),
        };
      }
      setSingleTissueQueryParams({ ...singleTissueQueryParams, ...param });
    },
    [singleTissueQueryParams]
  );

  const thresholdsHandler = useCallback(async () => {
    try {
      const values = await form.validateFields();
      setThresholds(values);
    } catch (e) {}
  }, [form]);

  const setAndGeneToolValue = useCallback(
    async (gene_id: string) => {
      setLocusComponentShow(false);
      setGeneSelect({ value: gene_id, label: gene_id });
      setLocusComponentShow(true);
      seteffectsizeShow(true);
      if (isOffline) {
        setGeneSelect({
          ...tissueGeneList[tissueSelect.value].filter(
            (geneItem: any) => geneItem.gene_id === gene_id
          )[0],
          value: gene_id,
          label: gene_id,
        });
      } else {
        try {
          setIsloadingForLocusPlot(true);
          const data: any = (
            await geneDetail({
              gene_id,
              colot_record_id: tissueSelect.recordId,
            })
          )?.data?.data;
          const snps: any = (
            await snpList({
              gene_id,
              colot_record_id: tissueSelect.recordId,
            })
          )?.data?.data;

          setGeneSelect({
            ...data,
            position: data.tss,
            gene_name: data.gene_symbol,
            value: gene_id,
            label: gene_id,
            h_target_rsid: snps.max,
          });
          setSingleAllTissueGeneSnpList(snps?.list);
          setIsloadingForLocusPlot(false);
        } catch (error) {
          setIsloadingForLocusPlot(false);
          setSingleAllTissueGeneSnpList([]);
          setGeneSelect({ value: gene_id, label: gene_id });
        }
      }
    },
    [isOffline, tissueGeneList, tissueSelect.recordId, tissueSelect.value]
  );
  console.log("summaryInfo",summaryInfo)
  // 第一次界面需要显示的数据,默认选择列表第一个

  useEffect(() => {
    if (isOffline || (!isOffline && location?.state?.task))
      getAnalysisResultMainData(isOffline, location?.state?.task).then(
        (data: any) => {
          if (!data) return;
          setMainData(data);
          setTraitSelect(data.data[0]);
          setTissueSelect(data.data[0]?.children?.[0]);
          setTissueToOrderBy(data.data[0]?.children?.[0]);
          // setToolSelect(data.data[0]?.children?.[0]?.children?.[3]);
          // setToolSelect({name: "predixcan", value:"predixcan", label:"predixcan"});
          // console.log("setToolSelect param", data.data[0]?.children?.[0]?.children?.[1]);
        }
      );
  }, [isOffline, location?.state?.task]);



  // summaryInfo?.tools.split(",")
  // summaryInfo
  // 获取 gene list
  useEffect(() => {
    // console.log("traitSelect:", traitSelect);
    // console.log("ascendRadio:", ascendRadio);
    // console.log("isOffline", isOffline);
    // console.log("tissueSelect:", tissueSelect);
    // console.log("thresholds:", thresholds);
    // console.log("singleTissueQueryParams:", singleTissueQueryParams);
    // console.log("mutilTissueQueryParams:", mutilTissueQueryParams);
    // console.log("tissueToOrderBy:", tissueToOrderBy);

    try {
      if (traitSelect?.value) {
        setIsloadingForHeatPlot(true);
        getAnalysisResultTraitTissueGenesData({
          isOffline,
          trait: traitSelect,
          tissue: tissueSelect,
          ascendRadio,
          thresholds,
          singleTissueQueryParams,
          mutilTissueQueryParams,
          orderByTissue: tissueToOrderBy,
        }).then((data: any) => {
          console.log("getAnalysisResultTraitTissueGenesData:",data);
          console.log("traitSelect:",traitSelect);

          if (data?.code == 400){
            setisNoColocResult("block");
            setisColocResult("none");
            setIsloadingForHeatPlot(false);
            setIsloadingForBarChart(false);
            setIsloadingForLocusPlot(false);
            setIsloadingForSummary(false);
          }
          else{
            setisNoColocResult("none");
            setisColocResult("block");
          setTissueGeneList(data.tissueList);
          setGeneTissueTable({
            dataSource: data.dataSource,
            mutiSize: data.mutiSize,
            singleSize: data.singleSize,
            columns: [
              { title: "gene", dataIndex: "gene", key: "gene" },
              ...data.columns.map((tissueItem: any) => {
                return {
                  ...tissueItem,
                  // 根据 gene 的信息展示颜色条
                  render: (geneItem: any) => (
                    <div>
                      {isOffline
                        ? geneItem &&
                          Object.keys(tool_map)
                            .filter((toolName: any) => geneItem[toolName] > -1)
                            .map((toolName: any, index: any) => {
                              return (
                                <Row
                                  style={{
                                    backgroundColor: tool_map[toolName],
                                  }}
                                  className="tool-div-line"
                                  key={index}
                                ></Row>
                              );
                            })
                        : geneItem > 0 &&
                          new Array(geneItem)
                            .fill(1)
                            .map((toolName: any, index: any) => {
                              return (
                                <Row
                                  style={{
                                    backgroundColor: "blue",
                                  }}
                                  className="tool-div-line"
                                  key={index}
                                ></Row>
                              );
                            })}
                    </div>
                  ),
                };
              }),
            ],
          });
          // console.log("data:", data);
          // console.log("GeneTissueTable:", geneTissueTable);  // 对应：Multi Tissue Gene List
          console.log("TissueGeneList:", tissueGeneList);  // 对应表格：Gene List
          setIsloadingForHeatPlot(false);}
        });
      }
    } catch (error) {
      setIsloadingForHeatPlot(false);
    }
  }, [
    traitSelect,
    ascendRadio,
    isOffline,
    tissueSelect,
    thresholds,
    singleTissueQueryParams,
    mutilTissueQueryParams,
    tissueToOrderBy
  ]);

  const [isDownloadTriggered, setIsDownloadTriggered] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // 控制按钮状态

  const downloadHtml = useCallback(() => {
    if (isDownloading || isLoadingForSummary) return; // 防止重复点击 & 等待加载完成
    
    setIsDownloading(true);  // 按钮变成 Waiting 状态
    setDisplayFlagBefore(DisplayFlag);
    setDisplayFlag(100);  // 先显示所有模块
    setIsDownloadTriggered(true); // 标记开始下载
  }, [isDownloading, isLoadingForSummary]);
  
  const isAllLoaded = useMemo(() => 
    ![isLoadingForSummary, isLoadingForBarChart, isLoadingForHeatPlot, isLoadingForLocusPlot].some(state => state), 
    [isLoadingForSummary, isLoadingForBarChart, isLoadingForHeatPlot, isLoadingForLocusPlot]
  );

  useEffect(() => {
    if (DisplayFlag === 100 && isDownloadTriggered && isAllLoaded) {
      setTimeout(() => {
        // 下载逻辑
        let htmlContent = document.documentElement.outerHTML;
  
        htmlContent = htmlContent.replace(
          /(<img\s+[^>]*src=['"])(.*?)['"]/gi,
          (match, p1, p2) => {
            return p1 + "./" + p2.split("/").pop() + '"';
          }
        );
        htmlContent = htmlContent.replace(
          /(<link\s+[^>]*href=['"])(.*?)['"]/gi,
          (match, p1, p2) => {
            return p1 + "./" + p2.split("/").pop() + '"';
          }
        );
  
        const zip = new JSZip();
        let blob = new Blob([htmlContent], { type: "text/html" });
        zip.file("index.html", blob);
  
        const zipFileName = "resources.zip";
        const externalResources = Array.from(
          document.querySelectorAll("img[src], link[href]")
        ).map((element: any) => element.src || element.href);
  
        const fetchPromises = externalResources.map((url, index) => {
          return fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const fileName = url.split("/").pop() || "";
              zip.file(fileName, blob);
            });
        });
  
        Promise.all(fetchPromises)
          .then(() => {
            zip.generateAsync({ type: "blob" }).then((zipBlob) => {
              const downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(zipBlob);
              downloadLink.download = zipFileName;
              downloadLink.style.display = "none";
  
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
  
              // 下载完成后恢复按钮状态
              setIsDownloading(false);
              setIsDownloadTriggered(false);
            });
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsDownloading(false);
            setIsDownloadTriggered(false);
          });
          setDisplayFlag(DisplayFlagBefore);  
      }, 4000); // 延时 4 秒执行下载
    }
  }, [DisplayFlag, isDownloadTriggered, isAllLoaded]);

  const downloadZipfile = useCallback(() => {
    const downloadLink = document.createElement("a");
    downloadLink.href = summaryInfo?.result_gz_file;
    downloadLink.download = "analysis-result.zip";
    downloadLink.click();
  }, [summaryInfo?.result_gz_file]);

  const downloadTablefile = useCallback(() => {
    const downloadLink = document.createElement("a");
    downloadLink.href = summaryInfo?.result_table_file;
    downloadLink.click();
  }, [summaryInfo?.result_table_file, tissueSelect.name]);

  // console.log("tissueSelect.value:", tissueSelect.value);
  console.log("tissueSelect:", tissueSelect);
  // console.log("tissueGeneList:", tissueGeneList);
  // let tool_arr_ = summaryInfo?.tools.split(",")
  // let tool_arr = tool_arr_!=undefined?tool_arr_:[]
  // console.log("summaryInfo?.tools",summaryInfo?.tools)
  // console.log('tool_arr:', tool_arr);
  // for (var in tool_arr){
  //   console.log('key: ', THRESHOLD_MAP_DETIAL[var])
  // }
  const dataSource =
    tissueGeneList &&
    tissueGeneList[tissueSelect.value]?.map((tissueItem: any) => {
      let {
        gene_id,
        coloc,
        smr,
        predixcan,
        fastenloc,
        ecaviar,
        fusion,
        intact,
        rank,
        rank_geo,
        count,
        gene_name,
        coloc_H3,
        p_HEIDI
      } = tissueItem;
      return {
        key: gene_id,
        gene: gene_id,
        gene_name: gene_name,
        coloc,
        smr,
        predixcan,
        fastenloc,
        ecaviar,
        fusion,
        rank_geo: isOffline ? tissueItem.geo_ranking : rank_geo,
        rank,
        intact: isOffline ? tissueItem.intact_probability : intact,
        count,
        coloc_H3,
        p_HEIDI
      };
    });

  console.log("Gene List dataSource:", dataSource);

  let columns: any = [
    {
      title: "Gene ID",
      dataIndex: "gene",
      key: "gene",
      width: 170,
      fixed: "left",
    },
    {
      title: "Gene Name",
      dataIndex: "gene_name",
      key: "gene_name",
      width: 170,
      fixed: "left",
    },
    ...Object.entries(THRESHOLD_MAP_DETIAL).map((item: any) => ({
      title: `${item[1].label}(${item[1].pLabel})`,
      dataIndex: item[0],
      key: item[0],
      // gene_name: item[0],
      sorter: true,
      sortDirections: isOffline ? [] : ["descend", "ascend"],
      render: (value: any) => parseValue(value),
    })),
    {
      title: "INTACT",
      dataIndex: "intact",
      key: "intact",
      sorter: true,
      sortDirections: isOffline ? [] : ["descend", "ascend"],
      render: (value: any) => parseValue(value),
    },
    {
      title: "coloc(H3)",
      dataIndex: "coloc_H3",
      key: "coloc_H3",
      sorter: false,
      sortDirections: isOffline ? [] : ["descend", "ascend"],
      render: (value: any) => parseValue(value),
    },
    {
      title: "p_HEIDI",
      dataIndex: "p_HEIDI",
      key: "p_HEIDI",
      sorter: false,
      sortDirections: isOffline ? [] : ["descend", "ascend"],
      render: (value: any) => parseValue(value),
    }
    // {
    //   title: "mean rank (geometric)",
    //   dataIndex: "rank_geo",
    //   key: "rank_geo",
    //   sorter: true,
    //   sortDirections: isOffline ? [] : ["descend", "ascend"],
    // },
  ];
  if (!isOffline) {
    columns = [
      ...columns,
      {
        title: "Votes",
        dataIndex: "count",
        key: "count",
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      // {
      //   title: "mean rank (arithmetic)",
      //   dataIndex: "rank",
      //   key: "rank",
      //   sorter: true,
      //   sortDirections: ["descend", "ascend"],
      //   fixed: "right",
      // },
    ];
  }

  const genesListInfo = useMemo(
    () => tissueGeneList && tissueGeneList[tissueSelect.value],
    [tissueGeneList, tissueSelect.value]
  );

  const plotData = useMemo(
    () =>
      isOffline
        ? tissueGeneList && tissueGeneList[tissueSelect.value]
        : singleAllTissueGeneList,
    [isOffline, singleAllTissueGeneList, tissueGeneList, tissueSelect.value]
  );
  const containerRef = useRef<any>(null);
  // console.log("dataSource111:",geneTissueTable.dataSource);
  return (
    <ResultRoot isOffline={isOffline} ref={containerRef}>
      {isOffline || (location?.state?.task?.task_id && status) ? (
        <>
          <div
            className="ResultItem"
            style={{
              marginTop: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Space>
              <Title level={3}>Result page</Title>
            </Space>

            {!isOffline && (
              <Space>
                <Button 
                  icon={isDownloading ? <Spin size="small" /> : <DownloadOutlined />} 
                  onClick={downloadHtml} 
                  disabled={isDownloading || isLoadingForSummary}
                >
                  {isDownloading ? "Waiting..." : "HTML Report"}
                </Button>
                <Button icon={<DownloadOutlined />} onClick={downloadZipfile}>
                  raw data
                </Button>
              </Space>
            )}
          </div>
          <div className="ResultItem">
            {isOffline && (
              <>
                <Row className="row-margin font-weight-format">
                  GWAS study: {mainData?.study}
                </Row>
                <Row className="row-margin">
                  <Col span={3} className="font-weight-format">
                    Trait Select :
                  </Col>
                  <Col>
                    <Select
                      showSearch
                      value={traitSelect.value}
                      className="StudySelect"
                      placeholder="Search trait"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        (option?.label ?? "").includes(input)
                      }
                      options={mainData?.data}
                      onChange={async (value, item) => {
                        setCollapseNum([]);
                        setGeneSelect({});
                        setLocusComponentShow(false);
                        seteffectsizeShow(false);
                        setTraitSelect(item);
                        setTissueSelect(item.children?.[0]);
                        setToolSelect(item.children?.[0]?.children?.[0]);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}

            <div>

              {/* Input */}
              <Row className="row-margin">
                {!isOffline && (
                  <GwasInputComponent
                    isLoadingForSummary={isLoadingForSummary}
                    summaryInfo={summaryInfo}
                    task_id={traitSelect.value}
                  />
                )}
              </Row>
              <div className="buttonContainer" style={{ display: DisplayTab, justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                <button
                  className={`buttonStyle ${DisplayFlag === 1 ? 'DisplayFlag' : ''}`}
                  onClick={() => handleButtonClick(1)}
                  style={{ padding: '12px 24px', fontSize: '20px', margin: '0 10px' }}
                >
                  GWAS Summary
                </button>
                <button
                  className={`buttonStyle ${DisplayFlag === 2 ? 'DisplayFlag' : ''}`}
                  onClick={() => handleButtonClick(2)}
                  style={{ padding: '12px 24px', fontSize: '20px', margin: '0 10px' }}
                >
                  Gene Implication Methods Results
                </button>
              </div>

            </div>
            <Row className={'buttonStyle'} style={{display: DisplayTabNot, padding: '12px 24px', fontSize: '20px', margin: '0 10px', alignContent: 'center', justifyContent: 'center'}}>
              GWAS Summary
            </Row>
            {/* configuration */}
            <Row className="row-margin" style={{display:DisplayConfigurations}}>
              {!isOffline && (
                <GwasInfoComponent
                  isLoadingForSummary={isLoadingForSummary}
                  summaryInfo={summaryInfo}
                  task_id={traitSelect.value}
                />
              )}
            </Row>
            {/* GWAS summary plot */}
            <Row className="gwasPlot" style={{display:DisplayGWASsummary}}>
              {/* <Collapse
                className="manhattan-collapse"
                defaultActiveKey={['1']}
                activeKey={['1']}
                onChange={(key: any) => {
                  setCollapseNum(key);
                }}
              >
                <Panel header="GWAS summary plots" key="1">
                  <ManhattanPlotComponent
                    isOffline={isOffline}
                    trait={traitSelect.value}
                    tissue={tissueSelect.value}
                    recordId={tissueSelect.recordId}
                  />
                  {!isOffline && (
                    <QQplot
                      isOffline={isOffline}
                      trait={traitSelect.value}
                      tissue={tissueSelect.value}
                      recordId={tissueSelect.recordId}
                    />
                  )}
                </Panel>
              </Collapse> */}
              <Row className="row-margin font-weight-format margin-top" style={{fontSize:'150%'}}>
                GWAS summary plots
              </Row>
              <React.Fragment>
                <ManhattanPlotComponent
                  isOffline={isOffline}
                  trait={traitSelect.value}
                  tissue={tissueSelect.value}
                  recordId={tissueSelect.recordId}
                />
                {!isOffline && (
                  <QQplot
                    isOffline={isOffline}
                    trait={traitSelect.value}
                    tissue={tissueSelect.value}
                    recordId={tissueSelect.recordId}
                  />
                )}
              </React.Fragment>
            </Row>
          </div>
          {/* <div className="ResultItem">
            <Row className="row-margin" style={{display:"None"}}>
              {!isOffline && (
                <MatchedSNPsummary
                  isLoadingForSummary={isLoadingForSummary}
                  summaryInfo={summaryInfo}
                  task_id={traitSelect.value}
                />
              )}
            </Row>
          </div> */}
          <Row className={'buttonStyle'} style={{display: DisplayTabNot, padding: '12px 24px', fontSize: '20px', margin: '0 10px', alignContent: 'center', justifyContent: 'center'}}>
            Gene Implication Methods Results
          </Row>
          <div className="ResultItem">
            <Row className="row-margin" gutter={16} style={{display:DisplayColocThre}}>
              <div style={{display:"flex", justifyContent:"center"}}>
        
                <Col span={18}>
                  <Card className="trait-choice-card"
                  style={{
                    // display: "flex",
                    flexDirection: "column",  // 保持垂直方向的布局
                    alignItems: "center",  // 水平方向居中
                    justifyContent: "center",  // 垂直方向居中
                  }}
                  >
                    <Row className="row-margin font-weight-format">Gene Implication Methods Threshold
                      <Tooltip title="Genes that do not exceed any threshold will not be displayed">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                    <Form
                      {...formItemLayout}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                      form={form}
                      initialValues={initialValues}
                      name="dynamic_form_complex"
                      style={{ maxWidth: 600 }}
                      autoComplete="off"
                    >
                      {Object.entries(THRESHOLD_MAP_DETIAL).map(
                        (item: any, index: number) => (
                          <Row style={{ width: "100%" }} key={index}>
                            <Col span={15}>
                              <Form.Item
                                key={index}
                                label={`${item[1].label} ${item[1].pLabel}`}
                                name={item[0]}
                              >
                                <InputNumber
                                  disabled={isOffline}
                                  min={0}
                                  max={1}
                                  step={0.01}
                                  style={{ margin: "0 16px" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={9}>
                              <span>
                                (default {item[1].pLabel}
                                {item[1].type}
                                {item[1].value_lable})
                              </span>
                            </Col>
                          </Row>
                        )
                      )}

                      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <Button
                          style={{ width: "20%" }}
                          type="primary"
                          htmlType="submit"
                          onClick={thresholdsHandler}
                        >
                          Update
                        </Button>

                        <Button
                          style={{ marginLeft: "10%", width: "20%" }}
                          type="default"
                          onClick={() => form.resetFields()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </div>
              <div style={{display:"flex", justifyContent:"center"}}>
                <Col span={18}>
                  <Card className="trait-choice-card">
                    <Row className="row-margin font-weight-format">
                      Sort Choice
                    </Row>
                    <Row className="row-margin">
                      <Col>
                        <Select
                          showSearch
                          value={tissueToOrderBy.value}
                          className="StudySelect"
                          placeholder="Search trait"
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            (option?.label ?? "").includes(input)
                          }
                          options={traitSelect?.children}
                          onChange={(value, item) => {
                            setTissueToOrderBy(item)
                            setGeneSelect({});
                            setLocusComponentShow(false);
                            seteffectsizeShow(false);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="row-margin">
                      <Col span={10}>
                        <Row align={"middle"}>
                          INTACT
                          <Tooltip
                            placement="top"
                            title={
                              "INTACT (INtegration of TWAS And ColocalizaTion) is an R package for constraining transcriptome-wide association study correlation statistics through an empirical Bayes approach. As input, INTACT takes gene-level colocalization probabilities and TWAS z-statistics, returning a posterior probability of putative causality for each gene. refer to https://github.com/jokamoto97/INTACT/"
                            }
                            arrow={true}
                          >
                            <QuestionCircleOutlined
                              style={{ color: "#9a9898" }}
                            />
                          </Tooltip>
                          :
                        </Row>
                        {!isOffline && (
                          <Row align={"middle"}>
                            mean rank (arithmetic)
                            <Tooltip
                              placement="top"
                              title={
                                "The arithmetic mean, also known as the average, is a measure of central tendency in statistics. It is calculated by summing up all the values in a dataset and then dividing the sum by the total number of values. The arithmetic mean provides a representative value that represents the typical or average value of the dataset."
                              }
                              arrow={true}
                            >
                              <QuestionCircleOutlined
                                style={{ color: "#9a9898" }}
                              />
                            </Tooltip>
                            :
                          </Row>
                        )}

                        <Row align={"middle"}>
                          mean rank (geometric)
                          <Tooltip
                            placement="top"
                            title={
                              "The geometric mean is a measure of central tendency in statistics that is calculated by taking the nth root of the product of n numbers. It is commonly used to calculate the average growth rate or rate of change over multiple periods. The geometric mean provides a representative value that accounts for the relative magnitudes of the numbers in the dataset, giving more weight to smaller values. It is particularly useful when dealing with quantities that exhibit exponential growth or decay."
                            }
                            arrow={true}
                          >
                            <QuestionCircleOutlined
                              style={{ color: "#9a9898" }}
                            />
                          </Tooltip>
                          :
                        </Row>
                      </Col>
                      <Col span={14}>
                        <Radio.Group
                          value={ascendRadio}
                          onChange={(e: any) => {
                            setAscendRadio(e.target.value);
                            setGeneSelect({});
                            setLocusComponentShow(false);
                            seteffectsizeShow(false);
                          }}
                          className="radio-group"
                        >
                          <Row>
                            <Radio value={0}>ascending</Radio>
                            <Radio value={1}>descending</Radio>
                          </Row>
                          {!isOffline && (
                            <Row>
                              <Radio value={2}>ascending</Radio>
                              <Radio value={3}>descending</Radio>
                            </Row>
                          )}

                          <Row>
                            <Radio value={4}>ascending</Radio>
                            <Radio value={5}>descending</Radio>
                          </Row>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </div> 
            </Row>
            <div className="ResultItem" style={{display:DisplayMultiTissue}}>
              <Row className="row-margin font-weight-format" style={{fontSize:'150%'}}>
                Multi Tissue Gene List
              </Row>
              <Row className="row-margin" justify="center" align="middle">
                <HeatChart
                  isLoadingForHeatPlot={isLoadingForHeatPlot}
                  dataSource={geneTissueTable.dataSource}
                  seletTools={summaryInfo?.tools.split(",")}
                  columns={geneTissueTable.columns}
                  taskId={traitSelect.value}
                  page={mutilTissueQueryParams}
                  isOffline={isOffline}
                />
                <Pagination
                  style={{ width: "100%", textAlign: "center" }}
                  current={mutilTissueQueryParams.page}
                  total={geneTissueTable?.mutiSize}
                  onChange={mutilTissueHandleChange}
                  defaultPageSize={10}
                  showSizeChanger={false}
                  showQuickJumper={true}
                />
              </Row>
            </div>

          <div className="ResultItem">

            <Row className="tissue-row-title juxtify-content-center" style={{display:DisplayTissueSelect}}>
              <Col span={6} className="font-weight-format" style={{fontSize:'150%'}}>
                Tissue Select :
              </Col>

              <Col>
                <Select
                  showSearch
                  value={tissueSelect.value}
                  className="StudySelect"
                  placeholder="Search trait"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    (option?.label ?? "").includes(input)
                  }
                  options={traitSelect?.children}
                  onChange={(value, item) => {
                    setTissueSelect(item);
                    setGeneSelect({});
                    setLocusComponentShow(false);
                    seteffectsizeShow(false);
                  }}
                />
              </Col>
            </Row>
          <div style={{display:DisplayUpsetplot}}>
            <div  style={{display:isNoColocResult}}>
              <h1 style={{width: "fit-content", position: "relative", margin: "auto"}}> No colocalization result found.</h1>
            </div>
            
            <div style={{display:isColocResult}}>
            <UpsetComponent
              trait={traitSelect.value}
              recordId={tissueSelect.recordId}
              limits={thresholds}
              isOffline={isOffline}
              genesListInfo={genesListInfo}
            ></UpsetComponent>
          </div>
            <Row className="row-margin font-weight-format" style={{display:isColocResult, fontSize:'150%'}}>
              <Button icon={<DownloadOutlined />} onClick={downloadTablefile}>
                  Table
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {tissueSelect.name} Gene List
              {/* (Dashed line indicates that no significant colocalization genes have been found) */}

            </Row>
            <Row className="tissue-row-title row-margin" style={{display:isColocResult}}>
              <Table
                scroll={{ x: 1500 }}
                loading={isLoadingForHeatPlot}
                className="gene-tissue-table"
                dataSource={dataSource}
                columns={columns as any}
                onChange={singleTissueHandleChange}
                pagination={{
                  total: geneTissueTable.singleSize,
                  current: singleTissueQueryParams.page,
                  pageSize: singleTissueQueryParams.page_size,
                  showQuickJumper: true,
                }}
              />
            </Row>
          </div>
          </div>
          <div style={{display:DisplayColocplot}}>
          <div className="ResultItem" style={{display:isColocResult}}>
            <Row className="tissue-row-title juxtify-content-center">
              <Col span={4} className="font-weight-format" style={{fontSize:'150%'}}>
                Tool Select
              </Col>

              {/* {summaryInfo?.tools.split(",").map((key:any)=>{console.log(THRESHOLD_MAP_DETIAL[key])})} */}
              <Col>
                <Select
                  showSearch
                  value={toolSelect.value}
                  className="StudySelect"
                  placeholder="Search trait"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    (option?.label ?? "").includes(input)
                  }
                  // options={summaryInfo?.tools.split(",").map((key:any)=>(Object.entries(THRESHOLD_MAP_DETIAL).map(
                  //   (item: any) => ({
                  //     label: `${item[1].label}(${item[1].pLabel})`,
                  //     value: item[0],
                  //   })
                  // )))}
                  options={summaryInfo?.tools.split(",").map((key:any)=>({
                      label: `${THRESHOLD_MAP_DETIAL[key].label} (${THRESHOLD_MAP_DETIAL[key].pLabel})`,
                      value: key,
                    }))
                  }
                  onChange={(value, item) => {
                    setToolSelect(item);
                    setGeneSelect({});
                    setLocusComponentShow(false);
                    seteffectsizeShow(false);
                  }}
                />
              </Col>
            </Row>
            <Row className="tissue-row-title juxtify-content-center">
              <BarChartComponent
                plotData={plotData}
                isLoadingForBarChart={isLoadingForBarChart}
                geneValue={toolSelect.value}
                isOffline={isOffline}
                setAndGeneToolValue={setAndGeneToolValue}
              />
            </Row>
            {/* Plot: GWAS effect sizes vs eQTL effect size */}

            
          </div>
          </div>

          </div>
          <div style={{display:DisplayGeneselect}}>
          <div className="ResultItem" style={{display:isColocResult}}>
            <Row className="tissue-row-title">
              <Col span={4} className="font-weight-format">
                GENE Select :
              </Col>
              <Col>
                <Select
                  showSearch
                  value={geneSelect?.value}
                  className="StudySelect"
                  placeholder="Search trait"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    (option?.label ?? "").includes(input)
                  }
                  options={(isOffline
                    ? tissueGeneList && tissueGeneList[tissueSelect.value]
                    : singleAllTissueGeneList
                  )?.map((geneItem: any) => ({
                    ...geneItem,
                    value: geneItem.gene_id,
                    label: geneItem.gene_name,
                  }))}
                  onChange={(value, item) => setAndGeneToolValue(value)}
                />
              </Col>
            </Row>

            {locusComponentShow && (
              <LocusPlotComponent
                locusComponentShow={locusComponentShow}
                isLoadingForLocusPlot={isLoadingForLocusPlot}
                snps={singleAllTissueGeneSnpList}
                isOffline={isOffline}
                tissue={tissueSelect.value}
                tissueName={tissueSelect.name}
                traitName={summaryInfo?.trait}
                recordId={tissueSelect.recordId}
                trait={traitSelect.value}
                gene={geneSelect}
                population={tissueSelect.population}
                setLocusPreocess={() => {}}
              />
            )}

            {effectsizeShow && (
              <CisEqtlComponent
                locusComponentShow={locusComponentShow}
                isLoadingForLocusPlot={isLoadingForLocusPlot}
                snps={singleAllTissueGeneSnpList}
                isOffline={isOffline}
                tissue={tissueSelect.value}
                tissueName={tissueSelect.name}
                traitName={summaryInfo?.trait}
                recordId={tissueSelect.recordId}
                trait={traitSelect.value}
                gene={geneSelect}
                population={tissueSelect.population}
                setLocusPreocess={() => {}}
              />
            )}
          </div>
          </div>
        </>
      ) : (
        summaryInfo?.eqtl && (
          <InputPage
            isLoadingForSummary={isLoadingForSummary}
            summaryInfo={summaryInfo}
          />
        )
      )}
      <FloatButton.BackTop
        style={{ bottom: 100 }}
        target={() => containerRef.current}
      />
    </ResultRoot>
  );
}
