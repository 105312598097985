import React from "react";
import { Container } from "./tasks";
// import icon_png from "../../icon.png";
import icon_png from "../../icon_new.png";
import effectsizeplot from "../../effectsizeplot.png";
import upload from "./icon/upload.png";
import locuscompareplot from "../../locuscompareplot.png";
import upsetplot_v2 from "../../upsetplot_v2.png";
import select from "./icon/select.png";
import select2 from "./icon/select2.png";
import config from "./icon/config.png";
import vis from "./icon/vis.png";
import download from "./icon/download.png";

import zhejiang_png from "../../zhejiang.png";
import nus_png from "../../nus.png";

import locuszoomplot from "../../locuszoomplot.png";
import { Divider, Timeline, Typography } from "antd";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
const { Title, Paragraph, Text, Link } = Typography;
export default function MainContent() {
  const toolsList = [
    [
      "eCAVIAR",
      "colocalization",
      ["https://www.cell.com/ajhg/fulltext/S0002-9297(16)30439-6"],
      `https://github.com/fhormoz/caviar`,
    ],
    [
      "SMR",
      "Summary-based Mendelian randomization",
      [`https://www.nature.com/articles/ng.3538`],
      "https://yanglab.westlake.edu.cn/software/smr/",
    ],
    [
      "fastEnloc",
      "colocalization",
      [
        `https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1006646`,
      ],
      "https://github.com/xqwen/fastenloc",
    ],
    [
      "coloc",
      "colocalization",
      [
        "https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009440",
      ],
      `https://chr1swallace.github.io/coloc/`,
    ],
    [
      "TWAS	",
      "Transcriptomic-wide association",
      [`https://www.nature.com/articles/ng.3506`],
      "http://gusevlab.org/projects/fusion/",
    ],
    [
      "PrediXcan",
      "Transcriptomic-wide association",
      [`https://www.nature.com/articles/ng.3367`],
      `https://github.com/hakyimlab/MetaXcan`,
    ],
  ];
  return (
    <Container>
      <Typography>
        {/* <Title level={1}>Home</Title> */}
        {/* <Divider /> */}
        {/* <div style={{ width: "100%", display: "flex"}}>
          <img src={icon_png} className="logo_locus_con_img" alt="" style={{flex:1, width: "10%"}}/>
          <Title level={1} style={{ textAlign: 'left', paddingTop:"5%",paddingLeft:"5%", flex:2}}>Welcome to LocusCompare v2</Title>
        </div> */}
        <div style={{background:"#4f5d73", marginTop:"-40px"}}>
          <Title level={1} style={{ textAlign: 'center', width:'100%', paddingTop:"5%", color:"white"}}>Welcome to LocusCompare v2 !</Title>
          
          <div style={{ width: "100%", display:"flex", position: "relative", textAlign: "center", maxWidth:"1170px", margin:"5% auto"}}>
            <div className="info-box" style={{zIndex:1, position:"relative", marginTop:"0%", paddingLeft:"4%", paddingRight:"4%", paddingBottom:"4%", textAlign:"center"}}>
              <h2 style={{marginTop:"0", color:"white"}}>Online ensemble GWAS-to-gene implication platform
              </h2>
              <p style={{marginTop:"0", maxWidth:"100%", margin: "0 auto", textAlign:"justify", color:"white"}}>
              LocusCompare2 integrates multiple Colocalization, Transcriptome-Wide Association Studies and Mendelian Randomization analysis to improve the accuracy and robustness of GWAS-to-gene implication.
              </p>
              {/* <a href="/#/home/analysis">Go to Analysis</a> */}
            </div>
            {/* <img src={icon_png} className="logo_locus_con_img" alt="" style={{position:"relative", zIndex:0, marginLeft:"-10%"}}/> */}
          </div>
        </div>
    
        {/* tools */}
        {/*
        <div style={{ width: "100%", marginTop:'5%', padding:'5%', display:"flex", position: "relative", background:"rgb(245, 245, 245)", justifyContent:"space-between", alignItems:"flex-start"}}>
          <div className="tool">
            <h2>Efficient processing method</h2>
            <p style={{marginTop:"8%"}}>LocusCompare2 accepts a GWAS as input and automatically identifies independent genome-wide significant loci through LD clumping. It includes 280 eQTL datasets, such as those from GTEx and the eQTL-Catalogue. Users can select multiple tissues to compare the effects of the GWAS file across different tissues. Various GIMs methods are provided for comprehensive insights into causal variants.
            </p>
          </div>
          <div className="tool-boxes">
            <div className="tool-box">
              <p>Selects significant GWAS loci
              </p>
            </div>
            <div className="tool-box">
              <p>Integrates 280 eQTL datasets
              </p>
            </div>
            <div className="tool-box">
              <p>Multi-tissue effect comparison
              </p>
            </div>
            <div className="tool-box">
              <p>Provides multiple GIMs methods
              </p>
            </div>
          </div>
        </div>
        */}

        {/* <div style={{ width: "100%", marginTop: '5%', padding: '5%', background:"rgb(245, 245, 245)"}}>
          <h2 style={{ marginBottom: '3%', textAlign:"center", marginTop:'0' }}>Turnkey solution for eQTL-based GWAS-to-gene implication analysis</h2>
          
          <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: '20px', marginTop:"5%" }}>
            <div className="tool-box-1" style={{ display: "flex", height:"100%", alignItems: "center", justifyContent: "center" }}>
              <h4>Upload full GWAS summary statistics
              </h4>
            </div>
            
            <div className="tool-box-1" style={{ display: "flex", height:"100%", alignItems: "center", justifyContent: "center" }}>
              <h4>Select multiple GWAS implication methods	
              </h4>
              
            </div>
            <div className="tool-box-1" style={{ display: "flex", height:"100%", alignItems: "center", justifyContent: "center" }}>
              <h4>Select multiple Tissues (280+ pre-loaded eQTL datasets)
              </h4>
            </div>
            
            <div className="tool-box-1" style={{ display: "flex", height:"100%", alignItems: "center", justifyContent: "center" }}>
              <h4>Interactive visualization
              </h4>
            </div>
            <div className="tool-box-1" style={{ display: "flex", height:"100%", alignItems: "center", justifyContent: "center" }}>
              <h4>Download results
              </h4>
            </div>
          </div>
        </div> */}

      

      <div style={{maxWidth:"1170px", margin:"0 auto"}}>

        <div style={{textAlign:"center", marginTop:"5%"}}>
          <img src={icon_png} className="logo_locus_con_img" alt="" style={{width: "80%"}}/>
        </div>

        <div style={{ width: "100%", marginTop: '5%', padding: '5%', background: "rgb(255, 255, 255)" }}>
          <h2 style={{ marginBottom: '3%', textAlign:"center", marginTop:'0' }}>Supports six Gene Implication Methods
          </h2>
          
          <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: '20px', marginTop:"5%" }}>
            <div className="tool-box-2" style={{ display: "flex", height:"180px", alignItems: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
              {/*<img src={icon_png} alt="" style={{ width: "10%", marginRight: '10px' }}/>*/}
              <h1>eCAVIAR</h1>
              <div style={{ display: "flex", justifyContent: "center", bottom: "2%", whiteSpace: "nowrap" }}>
                <a style={{display: "block", fontSize:"120%" }} href="https://github.com/fhormoz/caviar">code</a> 
                <a style={{ display: "block", fontSize:"120%",marginLeft:'10%' }} href="https://www.cell.com/ajhg/fulltext/S0002-9297(16)30439-6">paper</a>
              </div>
            </div>

            <div className="tool-box-2" style={{ display: "flex", height:"180px", alignItems: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              {/*<img src={icon_png} alt="" style={{ width: "10%", marginRight: '10px' }}/>*/}
              <h1>Coloc</h1>
              <div style={{ display: "flex", justifyContent: "center", bottom: "2%", whiteSpace: "nowrap" }}>
                <a style={{display: "block", fontSize:"120%" }} href="https://chr1swallace.github.io/coloc/">code</a> 
                <a style={{ display: "block", fontSize:"120%",marginLeft:'10%' }} href="https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1004383">paper</a>
              </div>
            </div>

            <div className="tool-box-2" style={{ display: "flex", height:"180px", alignItems: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              {/*<img src={icon_png} alt="" style={{ width: "10%", marginRight: '10px' }}/>*/}
              <h1>fastENLOC</h1>
              <div style={{ display: "flex", justifyContent: "center", bottom: "2%", whiteSpace: "nowrap" }}>
                <a style={{display: "block", fontSize:"120%" }} href="https://github.com/xqwen/fastenloc">code</a> 
                <a style={{ display: "block", fontSize:"120%",marginLeft:'10%' }} href="https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1006646">paper</a>
              </div>
            </div>

            <div className="tool-box-2" style={{ display: "flex", height:"180px", alignItems: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              {/*<img src={icon_png} alt="" style={{ width: "10%", marginRight: '10px' }}/>*/}
              <h1>FUSION</h1>
              <div style={{ display: "flex", justifyContent: "center", bottom: "2%", whiteSpace: "nowrap" }}>
                <a style={{display: "block", fontSize:"120%" }} href="http://gusevlab.org/projects/fusion/">code</a> 
                <a style={{ display: "block", fontSize:"120%",marginLeft:'10%' }} href="https://www.nature.com/articles/ng.3506">paper</a>
              </div>
            </div>

            <div className="tool-box-2" style={{ display: "flex", height:"180px", alignItems: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              {/*<img src={icon_png} alt="" style={{ width: "10%", marginRight: '10px' }}/>*/}
              <h1>PrediXcan</h1>
              <div style={{ display: "flex", justifyContent: "center", bottom: "2%", whiteSpace: "nowrap" }}>
                <a style={{display: "block", fontSize:"120%" }} href="https://github.com/hakyimlab/MetaXcan">code</a> 
                <a style={{ display: "block", fontSize:"120%",marginLeft:'10%' }} href="https://www.nature.com/articles/ng.3367">paper</a>
              </div>
            </div>

            <div className="tool-box-2" style={{ display: "flex", height:"180px", alignItems: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              {/*<img src={icon_png} alt="" style={{ width: "10%", marginRight: '10px' }}/>*/}
              <h1>SMR</h1>
              <div style={{ display: "flex", justifyContent: "center", bottom: "2%", whiteSpace: "nowrap" }}>
                <a style={{display: "block", fontSize:"120%" }} href="https://yanglab.westlake.edu.cn/software/smr/">code</a> 
                <a style={{ display: "block", fontSize:"120%",marginLeft:'10%' }} href="https://www.nature.com/articles/ng.3538">paper</a>
              </div>
            </div>
            
          </div>
        </div>

        <div style={{ width: "100%", marginTop: '5%', padding: '5%', background: "rgb(248, 248, 248)" }}>
          <h2 style={{ marginBottom: '3%', textAlign: "center", marginTop: '0' }}>Turnkey solution for eQTL-based GWAS-to-gene implication analysis</h2>

          <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: '20px', marginTop: "5%" }}>
            <div className="tool-box-1" style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <img src={upload} className="icon_step" alt="" style={{height:"30%"}}/>
              <h4>1. Upload full GWAS summary statistics</h4>
            </div>
            <div className="tool-box-1" style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <img src={select} className="icon_step" alt="" style={{height:"30%"}}/>
              <h4>2. Select multiple GWAS-to-gene implication methods</h4>
            </div>
            <div className="tool-box-1" style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <img src={select2} className="icon_step" alt="" style={{height:"30%"}}/>
              <h4>3. Select multiple Tissues/Cell types (280+ pre-loaded eQTL datasets)</h4>
            </div>
          </div>

          <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: '20px', marginTop: "5%" }}>
          <div className="tool-box-1" style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <img src={config} className="icon_step" alt="" style={{height:"30%"}}/>
              <h4>4. Parameter configuration</h4>
            </div>
            <div className="tool-box-1" style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <img src={vis} className="icon_step" alt="" style={{height:"30%"}}/>
              <h4>5. Interactive visualization</h4>
            </div>
            <div className="tool-box-1" style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center", borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <img src={download} className="icon_step" alt="" style={{height:"30%"}}/>
              <h4>6. Download results</h4>
            </div>
          </div>
        </div>


        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5%', marginTop: '-2%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '600px' }}>
            <a href="/#/home/analysis" className="gotoanalysisbutton">Run Analysis</a>
            <a href="/#/home/tasks" className="gotoanalysisbutton">Example output</a>
          </div>
        </div>

        {/* News */}
        <div style={{ width: "100%", marginTop: '5%', padding: '5%', display: "flex", position: "relative", background: "linear-gradient(135deg, #ffffff, #f0f0f0)", justifyContent: "space-between", alignItems: "flex-start", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", borderRadius: "10px" }}>
          <div className="news" style={{ width: "100%" }}>
            <h2 style={{ textAlign: "center", fontSize: "2.5em", fontFamily: "'Arial', sans-serif", color: "#333", marginBottom: "20px" }}>News</h2>
            <div style={{ marginTop: "5%", fontSize: "1.2em", lineHeight: "1.8", color: "#555" }}>
              <p style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px" }}><strong>Sep 2, 2024:</strong> LocusCompare2 version 2.0 released!</p>
              <p style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px", marginTop: "20px" }}><strong>Aug 1, 2024:</strong> LocusCompare2 integrates 230 datasets from the eQTL Catalogue.</p>
              <p style={{ marginTop: "20px" }}><strong>June 28, 2023:</strong> LocusCompare2 version 1.0 released!</p>
            </div>
          </div>
        </div>


        <div style={{ height:"100%", marginTop: '5%', padding: '5%'}}>
          <h2 style={{ marginBottom: '3%', textAlign:"center", marginTop:'0' }}>Diverse result displays</h2>
          
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: '5%', marginTop:"5%" }}>
            <div className="tool-box-3">
              <img src={upsetplot_v2} alt=""/>
              <p>Upset Plot</p>
            </div>
            <div className="tool-box-3">
              <img src={locuszoomplot} alt=""/>
              <p>Locus Zoom Plot </p>
            </div>
            <div className="tool-box-3">
              <img src={locuscompareplot} alt=""/>
              <p>Locuscompare Plot</p>
            </div>
            <div className="tool-box-3">
              <img src={effectsizeplot} alt=""/>
              <p>Effect Size Plot</p>
            </div>
          </div>
        </div>

  
        <div style={{ padding: 0 , display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{ width: "100%", marginTop: '5%', padding: '0%' }}>
            
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: '20px', marginTop:"5%", marginLeft:"12%" }}>
              <div className="tool-box-4" style={{ alignItems: "center" }}>
                <h2>Developers</h2>
                <span>
                    LocusCompare2 was developed by Boxiang Liu Lab and Zhejiang Lab.
                </span><br></br>
                {/* <span>Fei Liu (maintainer)</span><br></br>
                <span>Junbin Gao (maintainer)</span><br></br>
                <span>Yi Lin</span><br></br>
                <span>Wei Wang</span><br></br>
                <span>Jin Tang</span><br></br>
                <span>Haiyue Meng</span><br></br>
                <span>Yujiao Luo</span><br></br>
                <span>Liwen Duan</span><br></br>
                <span>Fei Guan</span><br></br>
                <span>Peizhe Wang</span><br></br>
                <span>Boxiang Liu</span><br></br> */}
                
                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: '20px', marginTop: "5%" }}>
                  <div className="tool-box-4" style={{ alignItems: "center" }}>
                    <img src={nus_png} className="icon_step" alt="" style={{height:"20%"}}/><br></br>
                    <span>Fei Liu (maintainer)</span><br></br>
                    <span>Junbin Gao (maintainer)</span><br></br>
                    <span>Fei Guan</span><br></br>
                    <span>Peizhe Wang</span><br></br>
                    <span>Boxiang Liu</span><br></br>
                  </div>
                  <div className="tool-box-4" style={{ alignItems: "center" }}>
                    <img src={zhejiang_png} className="icon_step" alt="" style={{height:"20%"}}/><br></br>
                    <span>Yi Lin</span><br></br>
                    <span>Wei Wang</span><br></br>
                    <span>Jin Tang</span><br></br>
                    <span>Haiyue Meng</span><br></br>
                    <span>Yujiao Luo</span><br></br>
                    <span>Liwen Duan</span><br></br>
                  </div>
                </div>
                
                
              </div>

              <div className="tool-box-4" style={{alignItems: "center" }}>
                <h2>Contact</h2>
                <span>We are grateful to the individuals and organizations who have made
          their data publicly available. If you would like to add your QTL study
          to LocusCompare2 database, please email: </span><br></br><br></br>
          <span>Boxiang Liu (boxiangliu@nus.edu.sg)</span><br></br>
          <span>Fei Liu (e1124850@u.nus.edu)</span> <br></br>
          <span>Junbin Gao (junbingao@u.nus.edu)</span><br></br><br></br>
          <span>If you have any questions when implementing LocusCompare2, you can email the authors or post your question on <a href="https://github.com/boxiangliulab/locuscompare2-standalone/issues">GitHub Issues</a>.  </span><br></br>
              </div>
            </div>

          </div>

        </div>

        {/* News */}
        <div style={{ width: "100%", marginTop:'0', padding:'5%', display:"flex", position: "relative", justifyContent:"space-between", alignItems:"flex-start"}}>
          <div style={{ width: "100%", paddingLeft:"7%" }}>
            <h2 style={{ textAlign: "left" }}>Citation</h2>
            <h4 style={{marginTop:"5%"}}></h4>
            <p>If you use LocusCompare v1, please cite the following paper:
              <a href="https://www.nature.com/articles/s41588-019-0404-0">
                Abundant associations with gene expression complicate GWAS follow-up
              </a></p>
          </div>
        </div>

        <div style={{width: '100%', margin: '0 auto', textAlign:'center'}}>CopyRight &copy; 2024 BoxiangLiu Lab All Rights Reserved.</div>


        {/* <Paragraph style={{marginTop:"5%"}}>
          LocusCompare2 performs colocalization, TWAS, and Mendelian
          randomization analysis across an ensemble of methods:
        </Paragraph>
        <div className="tool_wrap">
          {toolsList.map((tool: any) => (
            <div className="tool_cont">
              <Title level={5}>{tool[0]}</Title>
              <Paragraph style={{ marginBottom: 10 }}>{tool[1]}</Paragraph>
              <div>
                <ul style={{ display: "flex", marginBottom: 10 }}>
                  <li>
                    <a target="_blank" rel="noreferrer" href={tool[3]}>
                      code
                    </a>
                  </li>
                  {tool[2].map((item: any, index: number) => (
                    <li>
                      <a target="_blank" href={item} rel="noreferrer">
                        paper
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div> */}
        {/* <Paragraph>
          LocusCompare2 take a GWAS as input and automatically selects
          independent genome-wide significant loci through LD clumping. For each
          significant GWAS loci, LocusCompare2 search for overlapping QTL
          signals across all user-selected datasets and performs gene
          implication analysis for each (GWAS, QTL, method)-triplicate. Final
          results include the following:
        </Paragraph> */}
        {/* <ul>
          <li>
            Visualization of GWAS p-values through Manhattan plots and QQ plots.
          </li>
          <li>
            Aggregated results across multiple gene implication analysis
            methods.
          </li>
          <li>
            Ensemble ranking of putative risk genes through geometric mean rank
            and Bayesian model averaging (
            <a
              href="https://github.com/jokamoto97/INTACT/"
              target="_blank"
              rel="noreferrer"
            >
              INTACT
            </a>
            ).
          </li>
          <li>Visualization of putative risk genes using Manhattan plot.</li>
          <li>
            Interactive visualization and prioritization of target genes
            LocusZoom and LocusCompare plots.
          </li>
          <li>Prioritization of tissues types through GTEx expression.</li>
        </ul> */}

        {/* <Title level={4}>Developers</Title>
        <Paragraph>
          LocusCompare2 was developed by Boxiang Liu Lab and Zhejiang Lab.
        </Paragraph>
        <Paragraph>Yi Lin (maintainer)</Paragraph>
        <Paragraph>Wei Wang (maintainer)</Paragraph>
        <Paragraph>Jin Tang</Paragraph>
        <Paragraph>Haiyue Meng</Paragraph>
        <Paragraph>Yujiao Luo</Paragraph>
        <Paragraph>Liwen Duan</Paragraph>
        <Paragraph>Fei Guan</Paragraph>
        <Paragraph>Peizhe Wang</Paragraph>
        <Paragraph>Boxiang Liu</Paragraph>
        <Title level={4}>Contact</Title>
        <Paragraph>
          We are grateful to the individuals and organizations who have made
          their data publicly available. If you would like to add your QTL study
          to LocusCompare2 database, please email Yi Lin linyi1122@outlook.com.
        </Paragraph>
        <Title level={4}>Citation</Title>
        <Paragraph>
          If you use LocusCompare v1, please cite the following paper:
          <a href="https://www.nature.com/articles/s41588-019-0404-0">
            Abundant associations with gene expression complicate GWAS follow-up
          </a>
        </Paragraph> */}
        {/* <Title level={4}>News</Title>
        <Paragraph>
          <Timeline
            items={[
              {
                children: "June 28, 2023: LocusCompare2 version 1.0 released!",
              },
            ]}
          />
        </Paragraph> */}
      </div>
      </Typography>
    </Container>
  );
}
