import React, { useRef } from "react";
import { Container } from "../tasks";
import { Divider, Table, Typography, FloatButton, Row, Col } from "antd";
import config_png from "./assets/config.png";
import task_png from "./assets/task.png";
import choice_png from "./assets/choice.png";
// import input_1_png from "./assets/input-1.png";
// import input_2_png from "./assets/input-2.png";
import input_png from "./assets/input.png";
import gwas_png from "./assets/gwas.png";
import clump_png from "./assets/clump.png";
import manhattan_png from "./assets/manhattan.png";
import qqplot_png from "./assets/qqplot.png";
import heatmap_png from "./assets/heatmap.png";
import upset_png from "./assets/upset.png";
import result_table_png from "./assets/result_table.png";
import tools_png from "./assets/tools.png";
import coloc_png from "./assets/coloc.png";
import gene_png from "./assets/gene.png";
import tmp_png from "./assets/tmp.png";
import login_png from "./assets/login.png";
import effectsize_png from "./assets/effectsize.png";
import manhattan_qq_png from "./assets/manhattan_qq.png"
const { Title, Paragraph, Text, Link } = Typography;

export default function TutorialContent() {
  const dataSource = [
    ["GWAS file", "Input GWAS file, the reference genome should be hg38", "yes"],
    [
      "Type",
      "GWAS study type, cc: case/control, quant: quantitative trait",
      "yes",
    ],
    ["Trait name", "Trait name of GWAS study", "yes"],
    ["Test genomic window", "The genomic window used for colocalization GIMs. 'fixed-GWAS-Loci_window': Define a GWAS locus as the ±500kb around GWAS lead SNP; 'LD_based_window':Includes variants with LD r^2 > 0.1 with the lead SNP, along with an additional 50 kb flanking region on either side.", "yes"],
    ["Sample size", "GWAS sample size	", "yes"],
    [
      "GWAS threshold",
      "The maximum p-value for a SNP to be considered significant [default: 5e-8]",
      "yes",
    ],
    [
      "eQTL threshold",
      "The maximum p-value for a SNP to be considered significant [default: 1e-5]. For colocalization GIMs, if no significant SNPs in eQTL match the GWAS locus, this GWAS locus will be excluded from the analysis",
      "yes",
    ],
    ["rsID", "The column in the input GWAS file that represents each SNP using an rsID (e.g., rs13177956). Please note that it refers to the column name, not the name of the SNP itself.", "yes"],
    ["Variant ID", "The column in the input GWAS file that represents each SNP using an variant (e.g., chr5_133769073_A_G). Please note that it refers to the column name, not the name of the SNP itself.", "yes"],
    ["Chromosome", "The chromosome column name in the input GWAS file", "yes"],
    ["Position", "The SNP position column name in the input GWAS file", "yes"],
    [
      "Effect allele",
      "The effect allele column name in the input GWAS file",
      "yes",
    ],
    [
      "Other allele",
      "The other allele column name in the input GWAS file",
      "yes",
    ],
    ["P-value", "The p-value column name in the input GWAS file", "yes"],
    ["beta", "The beta column name in the input GWAS file", "yes"],
    ["Standard error", "The se column name in the input GWAS file", "yes"],
    ["Tools", "Run all tools by default", "yes"],
    [
      "eQTL",
      "can choose more than one eQTL file in a round, the reference genome should be hg38.",
      "yes",
    ],
    // [
    //   "p1(coloc)",
    //   "prior probability a SNP is associated with trait 1 [default 1e-4]",
    //   "optional",
    // ],
    // [
    //   "p2(coloc)",
    //   "prior probability a SNP is associated with trait 2 [default 1e-4]",
    //   "optional",
    // ],
    // [
    //   "p12(coloc)",
    //   "prior probability a SNP is associated with both traits [default 1e-5]",
    //   "optional",
    // ],
    // ["a₀(fastenloc)", "a₀ = p₀/(p₁+p₁₂) [default: 1e-4]", "optional"],
    // ["a₁(fastenloc)", "[default 1e-4]", "optional"],
  ].map((item, index) => ({
    key: index,
    param: item[0],
    des: item[1],
    required: item[2],
  }));

  const columns = [
    {
      title: "Parameters",
      dataIndex: "param",
      key: "param",
    },
    {
      title: "Description",
      dataIndex: "des",
      key: "des",
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "required",
    },
  ];
  const containerRef = useRef<any>(null);
  return (
    <Container ref={containerRef} style={{maxWidth:"1170px", margin:"0 auto"}}>
      <Typography>
        <Title level={3} style={{ textAlign: 'center' }}>Overview</Title>
        <Divider />
        <Title level={4}>1. Registration/Login</Title>
        <Paragraph>
          Please log in to your account before submitting analysis tasks. This
          enables you to track the progress of submitted tasks and view the
          results of finished tasks.
        </Paragraph>
        <Paragraph italic strong>
          Note: Results will be deleted after 14 days.
        </Paragraph>
        <Paragraph>
          Please register to create an account if you haven't registered yet.
        </Paragraph>
        <img
          src={login_png}
          alt=""
          width={"50%"}
          style={{ display: "block", margin: "20px auto" }}  // 设置 display: block 和 margin: auto
        />
        <Title level={4}>2. GWAS Format Requirement</Title>
        <Paragraph>
          The input GWAS file should be in a tabulated format. The following
          formats are supported:
        </Paragraph>
        <ol>
          <li>*.tsv, *tsv.gz</li>
          <li>*.csv, *csv.gz</li>
        </ol>
        <Paragraph>
          The GWAS file contain the following columns. The column names can be
          user-defined and will be specified in the input page.
        </Paragraph>
        <ol>
          <li>rsID</li>
          <li>chromosome</li>
          <li>base position</li>
          <li>effect allele</li>
          <li>noneffect allele</li>
          <li>p-value</li>
          <li>standard error</li>
          <li>beta</li>
        </ol>
        <Paragraph>Here is an example GWAS input file and you can download the example file by click "Download example file", the reference genome should be <strong>GRCh38</strong>.</Paragraph>
        <img src={gwas_png} alt="" width={"100%"} style={{ margin: "20px 0" }} />
        <Title level={4}>3. Configure and submit a new analysis</Title>
        <Paragraph>
          You can configure analysis settings under "Run Analysis" and then
          submit your task.
        </Paragraph>
        {/* <img
          src={input_1_png}
          alt=""
          width={"100%"}
          style={{ marginTop: "20px" }}
        />
        <img
          src={input_2_png}
          alt=""
          style={{ marginTop: "-10px" }}
          width={"100%"}
        /> */}
        <img
          src={input_png}
          alt=""
          style={{ marginTop: "-10px" }}
          width={"100%"}
        /> 
        <Paragraph>
          The parameters and their descriptions are listed below.
        </Paragraph>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        <Title level={4}>4. Check Results</Title>
        <Paragraph>
          All running and finished tasks are displayed in the "Tasks" section.
          You can click on the "+” sign next to the task names to show task
          status and details, as well as to access the results page by clicking
          on the task name. Below is an example task page.
        </Paragraph>
        <img
          src={task_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Title level={4}>5. Results Page</Title>
        <Paragraph>
          The results page will show the comprehensive results and configuration
          for this colocalization analysis. You can download the report from the
          upper right corner of the results page. Config file and log file of 
          this task can be found in the "Configurations" tab.
        </Paragraph>
        <img
          src={config_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Title level={5}>GWAS significant loci</Title>
        <Paragraph>
          The table in tab "GWAS summary" displays all independent GWAS significant loci
          selected via LD clumping. Each row represent the lead SNP of each GWAS
          loci, and contains the following information:
        </Paragraph>
        <ul>
          <li>rsID, start, end, p-value, variant ID of the lead SNP </li>
          <li>the number of SNPs in this loci: total number of SNPs in this loci</li>
        </ul>
        <Paragraph>
          You can sort lead SNPs by genomic position or p-value.
        </Paragraph>
        <img
          src={clump_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Title level={5}>GWAS summary plots</Title>
        <Paragraph>
          You can find the Manhattan and QQ plots of the GWAS data in "GWAS summary plots".
        </Paragraph>
        {/* <img
          src={manhattan_png}
          alt=""
          height={"500"}
          style={{ margin: "20px 0" }}
        />
        <img
          src={qqplot_png}
          alt=""
          height={"500"}
          style={{ margin: "20px 0" }}
        /> */}
        <img
          src={manhattan_qq_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Title level={5}>Threshold</Title>
        <Paragraph>
          In the "Threshold" section, you can adjust the significance thresholds
          for each gene implication method. Additionally, you can choose to rank
          genes by Baysian model averaging (INTACT), arithmetic mean ranking, or
          geometric mean ranking . Click on “Update” to update the results based
          on your selections.
        </Paragraph>
        <img
          src={choice_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Title level={5}>Multi Tissue Gene List</Title>
        <Paragraph>
          This heatmap indicates the number of gene implication methods that
          considers the gene as a risk gene. Each column represents a QTL study,
          and each row represents a gene. Hovers over each square to display
          detailed information about this gene-QTL pair.
        </Paragraph>
        <img
          src={heatmap_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Title level={5}>Tissue Selection</Title>
        <Paragraph>
          You can select a tissue of interest in the "Tissue Selection" section.
          The Upset plot displays the number of colocalized genes across
          different tools for the selected tissue. The left histogram shows the
          number of colocalized genes for each method, and the top histogram
          shows the number of colocalized genes for intersection of different
          methods. Additionally, the list below provides detailed information on
          these genes.
        </Paragraph>
        <img
          src={upset_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <img
          src={result_table_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Title level={5}>Method Selection</Title>
        <Paragraph>
          You can choose an analysis method in the "Tool Selection" section. A
          Manhattan plot will be displayed to show summary statistics generated
          by that method. Each dot represents a specific gene. There are two
          ways to select a gene:
        </Paragraph>
        <ol>
          <li>
            You can select a gene by clicking on its dot, and a LocusCompare
            plot will be shown to visualize the colocalization between the GWAS
            and QTL .
          </li>
          <li>
            Alternatively, you can also select a gene from the drop-down menu.
          </li>
        </ol>
        <img
          src={tools_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Paragraph>
          About LocusCompare plot, each dot represents a variant and is colored
          according to its linkage disequilibrium (LD) to the selected variant.
          A bona fide colocalization signal should form a single spike toward
          the top right corner.
        </Paragraph>
        <img
          src={coloc_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Paragraph>
          In a LocusCompare plot, each dot represents a SNP and each SNP is
          colored according to its linkage disequilibrium (LD) to the selected
          variant. A bona fide colocalization signal should form a single spike
          toward the top right corner.
        </Paragraph>
        <img
          src={gene_png}
          alt=""
          width={"100%"}
          style={{ margin: "20px 0" }}
        />
        <Paragraph>
          The "GTEx Gene Expression" graph displays the expression level of the
          selected gene across all eQTL (tissue-specific) datasets from GTEx.
        </Paragraph>
        <img src={tmp_png} alt="" width={"100%"} style={{ margin: "20px 0" }} />
        <Paragraph>
          In an Effect size plot, each dot represents a SNP and each SNP is
          colored according to its linkage disequilibrium (LD) to the selected
          variant. To clearly demonstrate the relationship between the beta 
          values of the GWAS summary statistics and eQTL data, SNPs located 
          within ±100kb of the lead SNP are included. Pearson correlations 
          between the GWAS and eQTL are also provided.
        </Paragraph>
        <img
          src={effectsize_png}
          alt=""
          width={"55%"}
          style={{ display: "block", margin: "20px auto" }}
        />
        <Title level={5}>Gene Information List and SNP Information List</Title>
        <Paragraph>
          Towards the end of the report, you will find more information about
          the selected gene. These information includes:
        </Paragraph>
        <ol>
          <li>Genes symbol and genomic location</li>
          <li>Gene implication methods and their corresponding scores</li>
          <li>
            SNP genomic location, alleles, and minor allele frequencies across
            ancestries
          </li>
          <li>SNP QTL p-value, GWAS p-value and LD</li>
        </ol>
        <Paragraph>
          Note that the list located in the lower right corner only contains
          SNPs with an LD r2 {">"} 0.2 to the selected variant.
        </Paragraph>
        <FloatButton.BackTop
          style={{ bottom: 100 }}
          target={() => containerRef.current}
        />
      </Typography>
    </Container>
  );
}
